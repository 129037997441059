var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ApplicationBar", { on: { toggle: _vm.toggleMenu } }),
      _vm._v(" "),
      !_vm.mobileNavi && _vm.drawer
        ? _c(
            "v-navigation-drawer",
            {
              style: _vm.ajustment,
              attrs: {
                "hide-overlay:true": "",
                "mobile-breakpoint": 480,
                width: "256px",
                app: "",
              },
            },
            [
              _c("div", { staticClass: "nav" }, [
                _c("div", { staticClass: "profile" }, [
                  _vm.userStore.context.state.loginUser.company_logo_file
                    ? _c("img", {
                        staticClass: "company-logo",
                        attrs: {
                          src:
                            _vm.$fileBaseUrl +
                            "/picture/bank/" +
                            _vm.userStore.context.state.loginUser
                              .company_logo_file.file_unique_code,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", { staticClass: "campany-name" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.userStore.context.state.loginUser.company_name
                        ) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "user-name" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.userStore.context.state.loginUser.user_last_name
                        ) +
                        " " +
                        _vm._s(
                          _vm.userStore.context.state.loginUser.user_first_name
                        ) +
                        "さん\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "menu" },
                  _vm._l(_vm.items, function (item, index) {
                    return _c("div", { key: index }, [
                      !(item.path === "/mypage/sc" && !_vm.openSC)
                        ? _c("div", [
                            !item.sub
                              ? _c(
                                  "div",
                                  [
                                    item.path === "/fukuri"
                                      ? [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "menu-link router-link-exact-active router-link-active",
                                              attrs: { href: "javascript:;" },
                                              on: { click: _vm.transferFukuri },
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "for-click" },
                                                [
                                                  _c("i", { class: item.icon }),
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(item.title) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      : item.path === "/payment"
                                      ? [
                                          _vm.showPaymentNewBadge
                                            ? [
                                                _c(
                                                  "v-badge",
                                                  {
                                                    attrs: {
                                                      left: "",
                                                      color: "error",
                                                      content: "new",
                                                      "offset-y": "20x",
                                                      "offset-x": "38",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "menu-link router-link-exact-active router-link-active",
                                                        attrs: {
                                                          href: "javascript:;",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.transferPayment,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "for-click",
                                                          },
                                                          [
                                                            _c("i", {
                                                              class: item.icon,
                                                            }),
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  item.title
                                                                ) +
                                                                "\n                      "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "menu-link router-link-exact-active router-link-active",
                                                    attrs: {
                                                      href: "javascript:;",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.transferPayment,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "for-click",
                                                      },
                                                      [
                                                        _c("i", {
                                                          class: item.icon,
                                                        }),
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(item.title) +
                                                            "\n                    "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                        ]
                                      : [
                                          _c(
                                            "nuxt-link",
                                            {
                                              staticClass:
                                                "menu-link router-link-exact-active router-link-active",
                                              attrs: {
                                                to: item.path,
                                                "aria-current": "page",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "for-click" },
                                                [
                                                  _c("i", { class: item.icon }),
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(item.title) +
                                                      "\n                    "
                                                  ),
                                                  _vm._v(" "),
                                                  item.path ===
                                                    "/mypage/bachat" &&
                                                  _vm.bachatRoomsUnreadCount > 0
                                                    ? _c(
                                                        "v-chip",
                                                        {
                                                          staticClass:
                                                            "pl-0 ml-1 pr-4",
                                                          attrs: {
                                                            color: "warning",
                                                            small: "",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                _vm.bachatRoomsUnreadCount
                                                              ) +
                                                              "\n                    "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  item.path === "/chat" &&
                                                  _vm.chatRoomsUnreadCount > 0
                                                    ? _c(
                                                        "v-chip",
                                                        {
                                                          staticClass:
                                                            "pl-0 ml-1 pr-4",
                                                          attrs: {
                                                            color: "warning",
                                                            small: "",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                _vm.chatRoomsUnreadCount
                                                              ) +
                                                              "\n                    "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  (_vm.userStore.context.state
                                                    .loginUser.user_type ===
                                                    "company_owner" ||
                                                    _vm.userStore.context.state
                                                      .loginUser.user_type ===
                                                      "partner_company_owner") &&
                                                  item.path ===
                                                    "/mypage/news" &&
                                                  _vm.unreadCmiCount > 0
                                                    ? _c(
                                                        "v-chip",
                                                        {
                                                          staticClass:
                                                            "pl-0 ml-1 pr-4",
                                                          attrs: {
                                                            color: "warning",
                                                            small: "",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                _vm.unreadCmiCount
                                                              ) +
                                                              "\n                    "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                  ],
                                  2
                                )
                              : _c("div", [
                                  _c("input", {
                                    attrs: {
                                      id: index,
                                      type: "checkbox",
                                      name: "check",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("label", { attrs: { for: index } }, [
                                    _c(
                                      "p",
                                      { staticClass: "menu-level-1" },
                                      [
                                        (item.title ===
                                          "クラウドファンディング" &&
                                          _vm.showCampfireNewBadge) ||
                                        (item.title === "事業承継" &&
                                          _vm.showBusinessSuccessionNewBadge) ||
                                        (item.title === "勤怠連携サービス" &&
                                          _vm.showAttendanceLinkageNewBadge)
                                          ? _c(
                                              "v-badge",
                                              {
                                                attrs: {
                                                  color: "error",
                                                  content: "new",
                                                  left: "",
                                                  small: "",
                                                  "offset-x": "22",
                                                  "offset-y": "1",
                                                },
                                              },
                                              [_c("i", { class: item.icon })]
                                            )
                                          : _c("i", { class: item.icon }),
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(item.title) +
                                            "\n                "
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "menu-list" },
                                      _vm._l(item.sub, function (sub, idx) {
                                        return _c(
                                          "div",
                                          { key: idx },
                                          [
                                            sub.path ===
                                            "/mypage/matching/company/"
                                              ? [
                                                  _c(
                                                    "nuxt-link",
                                                    {
                                                      staticClass:
                                                        "menu-level-2",
                                                      attrs: {
                                                        to:
                                                          sub.path +
                                                          _vm.userStore.context
                                                            .state.loginUser
                                                            .company_unique_code,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "for-click",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                sub.title
                                                              ) +
                                                              "\n                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              : sub.path === "/fukuri"
                                              ? [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "menu-level-2",
                                                      attrs: {
                                                        href: "javascript:;",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.transferFukuri,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "for-click",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                sub.title
                                                              ) +
                                                              "\n                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              : sub.path ===
                                                "/mypage/payment/card/create"
                                              ? [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "menu-level-2",
                                                      attrs: { href: sub.path },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "for-click",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                sub.title
                                                              ) +
                                                              "\n                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              : sub.path ===
                                                "/mypage/chanto-attendance/application"
                                              ? [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "menu-level-2",
                                                      attrs: {
                                                        href: "javascript:;",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.showIcnextApplicationModal,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "for-click",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                sub.title
                                                              ) +
                                                              "\n                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              : [
                                                  _c(
                                                    "nuxt-link",
                                                    {
                                                      staticClass:
                                                        "menu-level-2",
                                                      attrs: { to: sub.path },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "for-click",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                sub.title
                                                              ) +
                                                              "\n                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                          ],
                                          2
                                        )
                                      }),
                                      0
                                    ),
                                  ]),
                                ]),
                          ])
                        : _vm._e(),
                    ])
                  }),
                  0
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.mobileNavi && _vm.device === "sp"
        ? _c(
            "v-dialog",
            {
              attrs: {
                dark: "",
                fullscreen: "",
                "hide-overlay": "",
                transition: "dialog-bottom-transition",
              },
              model: {
                value: _vm.mobileNavi,
                callback: function ($$v) {
                  _vm.mobileNavi = $$v
                },
                expression: "mobileNavi",
              },
            },
            [
              _c(
                "div",
                { staticClass: "nav" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "" } },
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "pr-5",
                          attrs: { icon: "", dark: "" },
                          on: {
                            click: function ($event) {
                              _vm.mobileNavi = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "menu",
                      style:
                        _vm.device === "tablet"
                          ? "max-height: 100vh; height: 100vh;"
                          : "",
                    },
                    [
                      _vm._l(_vm.items, function (item, index) {
                        return _c("div", { key: index }, [
                          !(item.path === "/mypage/sc" && !_vm.openSC)
                            ? _c("div", [
                                !item.sub
                                  ? _c(
                                      "div",
                                      [
                                        item.path === "/fukuri"
                                          ? [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "menu-link router-link-exact-active router-link-active",
                                                  attrs: {
                                                    href: "javascript:;",
                                                  },
                                                  on: {
                                                    click: _vm.transferFukuri,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "for-click",
                                                    },
                                                    [
                                                      _c("i", {
                                                        class: item.icon,
                                                      }),
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(item.title) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : item.path === "/payment"
                                          ? [
                                              _vm.showPaymentNewBadge
                                                ? [
                                                    _c(
                                                      "v-badge",
                                                      {
                                                        attrs: {
                                                          left: "",
                                                          color: "error",
                                                          content: "new",
                                                          "offset-y": "20x",
                                                          "offset-x": "38",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "menu-link router-link-exact-active router-link-active",
                                                            staticStyle: {
                                                              width: "100vw",
                                                            },
                                                            attrs: {
                                                              href: "javascript:;",
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.transferPayment,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "for-click",
                                                              },
                                                              [
                                                                _c("i", {
                                                                  class:
                                                                    item.icon,
                                                                }),
                                                                _vm._v(
                                                                  "\n                        " +
                                                                    _vm._s(
                                                                      item.title
                                                                    ) +
                                                                    "\n                      "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                : [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "menu-link router-link-exact-active router-link-active",
                                                        staticStyle: {
                                                          width: "100vw",
                                                        },
                                                        attrs: {
                                                          href: "javascript:;",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.transferPayment,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "for-click",
                                                          },
                                                          [
                                                            _c("i", {
                                                              class: item.icon,
                                                            }),
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  item.title
                                                                ) +
                                                                "\n                    "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                            ]
                                          : [
                                              _c(
                                                "nuxt-link",
                                                {
                                                  staticClass:
                                                    "menu-link router-link-exact-active router-link-active",
                                                  attrs: {
                                                    to: item.path,
                                                    "aria-current": "page",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "for-click",
                                                    },
                                                    [
                                                      _c("i", {
                                                        class: item.icon,
                                                      }),
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(item.title) +
                                                          "\n                    "
                                                      ),
                                                      _vm._v(" "),
                                                      item.path ===
                                                        "/mypage/bachat" &&
                                                      _vm.bachatRoomsUnreadCount >
                                                        0
                                                        ? _c(
                                                            "v-chip",
                                                            {
                                                              staticClass:
                                                                "pl-0 ml-1 pr-4",
                                                              attrs: {
                                                                color:
                                                                  "warning",
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                      " +
                                                                  _vm._s(
                                                                    _vm.bachatRoomsUnreadCount
                                                                  ) +
                                                                  "\n                    "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      item.path === "/chat" &&
                                                      _vm.chatRoomsUnreadCount >
                                                        0
                                                        ? _c(
                                                            "v-chip",
                                                            {
                                                              staticClass:
                                                                "pl-0 ml-1 pr-4",
                                                              attrs: {
                                                                color:
                                                                  "warning",
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                      " +
                                                                  _vm._s(
                                                                    _vm.chatRoomsUnreadCount
                                                                  ) +
                                                                  "\n                    "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      item.path ===
                                                        "/mypage/news" &&
                                                      _vm.unreadCmiCount > 0
                                                        ? _c(
                                                            "v-chip",
                                                            {
                                                              staticClass:
                                                                "pl-0 ml-1 pr-4",
                                                              attrs: {
                                                                color:
                                                                  "warning",
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                      " +
                                                                  _vm._s(
                                                                    _vm.unreadCmiCount
                                                                  ) +
                                                                  "\n                    "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                      ],
                                      2
                                    )
                                  : _c("div", [
                                      _c("input", {
                                        attrs: {
                                          id: index,
                                          type: "checkbox",
                                          name: "check",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("label", { attrs: { for: index } }, [
                                        _c(
                                          "p",
                                          { staticClass: "menu-level-1" },
                                          [
                                            (item.title ===
                                              "クラウドファンディング" &&
                                              _vm.showCampfireNewBadge) ||
                                            (item.title === "事業承継" &&
                                              _vm.showBusinessSuccessionNewBadge) ||
                                            (item.title ===
                                              "勤怠連携サービス" &&
                                              _vm.showAttendanceLinkageNewBadge)
                                              ? _c(
                                                  "v-badge",
                                                  {
                                                    attrs: {
                                                      color: "error",
                                                      content: "new",
                                                      left: "",
                                                      small: "",
                                                      "offset-x": "22",
                                                      "offset-y": "1",
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      class: item.icon,
                                                    }),
                                                  ]
                                                )
                                              : _c("i", { class: item.icon }),
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(item.title) +
                                                "\n                "
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "menu-list" },
                                          _vm._l(item.sub, function (sub, idx) {
                                            return _c(
                                              "div",
                                              { key: idx },
                                              [
                                                sub.path ===
                                                "/mypage/matching/company/"
                                                  ? [
                                                      _c(
                                                        "nuxt-link",
                                                        {
                                                          staticClass:
                                                            "menu-level-2",
                                                          attrs: {
                                                            to:
                                                              sub.path +
                                                              _vm.userStore
                                                                .context.state
                                                                .loginUser
                                                                .company_unique_code,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "for-click",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    sub.title
                                                                  ) +
                                                                  "\n                        "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  : sub.path === "/fukuri"
                                                  ? [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "menu-level-2",
                                                          attrs: {
                                                            href: "javascript:;",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.transferFukuri,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "for-click",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    sub.title
                                                                  ) +
                                                                  "\n                        "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  : sub.path ===
                                                    "/mypage/payment/card/create"
                                                  ? [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "menu-level-2",
                                                          attrs: {
                                                            href: sub.path,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "for-click",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    sub.title
                                                                  ) +
                                                                  "\n                        "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  : sub.path ===
                                                    "/mypage/chanto-attendance/application"
                                                  ? [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "menu-level-2",
                                                          attrs: {
                                                            href: "javascript:;",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.showIcnextApplicationModal,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "for-click",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    sub.title
                                                                  ) +
                                                                  "\n                        "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  : [
                                                      _c(
                                                        "nuxt-link",
                                                        {
                                                          staticClass:
                                                            "menu-level-2",
                                                          attrs: {
                                                            to: sub.path,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "for-click",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    sub.title
                                                                  ) +
                                                                  "\n                        "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                              ],
                                              2
                                            )
                                          }),
                                          0
                                        ),
                                      ]),
                                    ]),
                              ])
                            : _vm._e(),
                        ])
                      }),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "div",
                          [
                            _c(
                              "nuxt-link",
                              {
                                staticClass:
                                  "menu-link router-link-exact-active router-link-active",
                                attrs: { to: "/logout" },
                              },
                              [
                                _c("span", { staticClass: "for-click" }, [
                                  _c("i", { staticClass: "far fa-sign-out" }),
                                  _vm._v(
                                    "\n                ログアウト\n              "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.device === "sp"
                    ? _c(
                        "v-footer",
                        {
                          staticClass: "my-11",
                          attrs: { align: "center", dark: "", padless: "" },
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "py-2",
                              attrs: { justify: "center", "no-gutters": "" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "px-0",
                                  attrs: {
                                    small: "",
                                    color: "white",
                                    text: "",
                                    to: "/page/rule",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.appStore.settingContents
                                          .footer_rule_link_string
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.appStore.context.state.appConfig.customize
                                .enable_footer_rc_rule_visible
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "pl-0",
                                      attrs: {
                                        color: "white",
                                        text: "",
                                        to: "/page/rule_rc",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            R&C会員規約\n          "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "pl-0",
                                  attrs: {
                                    small: "",
                                    color: "white",
                                    text: "",
                                    href: _vm.appStore.context.state.appConfig
                                      .privacy_url,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.appStore.settingContents
                                          .footer_privacy_policy_link_string
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "px-0",
                                  attrs: {
                                    small: "",
                                    color: "white",
                                    text: "",
                                    to: "/inquiry",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            お問い合わせ\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "px-0",
                                  attrs: {
                                    small: "",
                                    color: "white",
                                    text: "",
                                    to: "/mypage/help",
                                  },
                                },
                                [_vm._v("\n            ヘルプ\n          ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showIcnextDialog ? _c("icnextApplicationModal") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }