var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize",
        },
      ],
    },
    [
      _c(
        "v-navigation-drawer",
        {
          staticClass: "grey lighten-5",
          attrs: {
            app: "",
            clipped: "",
            "mobile-breakpoint": 600,
            width: "285",
            height: "100%",
          },
          model: {
            value: _vm.showMenu,
            callback: function ($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu",
          },
        },
        [
          _vm.switchingUser
            ? _c(
                "v-sheet",
                {
                  staticClass: "pa-2 text-center",
                  attrs: { color: "text-center red darken-3" },
                },
                [
                  _c(
                    "a",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: { click: _vm.cancelSwitching },
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { width: "256px" } },
                        [
                          _c("v-icon", {
                            attrs: { dark: "" },
                            domProps: {
                              textContent: _vm._s("mdi-account-switch"),
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "caption white--text" }, [
                            _vm._v(
                              "\n            ユーザー切替中(戻る)\n          "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-sheet",
            { staticClass: "py-5 px-2 text-center", attrs: { color: "white" } },
            [
              _c("p", { staticClass: "body-1" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.userStore.loginUser.user_bb_name) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.userStore.loginUser.user_last_name) +
                    "\n        " +
                    _vm._s(_vm.userStore.loginUser.user_first_name) +
                    "さん\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "caption" },
                [
                  _c("nuxt-link", { attrs: { to: "/logout" } }, [
                    _vm._v(" ログアウト"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isMobile
                ? _c(
                    "div",
                    { staticClass: "ml-n1 mt-5 ml-n5" },
                    [
                      _vm.unapprovedChatCount > 0
                        ? _c(
                            "v-badge",
                            {
                              staticClass: "pl-5 mt-2",
                              attrs: {
                                color: "pink",
                                content: _vm.unapprovedChatCount,
                              },
                            },
                            [
                              _c(
                                "nuxt-link",
                                {
                                  attrs: {
                                    to: "/admin/company/support/approval",
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-message-text")])],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.noreplyChatCount > 0
                        ? _c(
                            "v-badge",
                            {
                              staticClass: "pl-5 mt-2",
                              attrs: {
                                color: "pink",
                                content: _vm.noreplyChatCount,
                              },
                            },
                            [
                              _c(
                                "nuxt-link",
                                { attrs: { to: "/admin/bachat/noreply" } },
                                [_c("v-icon", [_vm._v("mdi-email")])],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.closedInquiryCount > 0
                        ? _c(
                            "v-badge",
                            {
                              staticClass: "pl-5 mt-2",
                              attrs: {
                                color: "pink",
                                content: _vm.closedInquiryCount,
                              },
                            },
                            [
                              _c(
                                "nuxt-link",
                                { attrs: { to: "/admin/inquiry" } },
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-comment-account-outline"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-list",
            { attrs: { nav: "", dense: "", rounded: "" } },
            _vm._l(_vm.appStore.navMenuItems, function (item, index) {
              return _c(
                "v-list-group",
                {
                  key: index,
                  attrs: {
                    "no-action": "",
                    "append-icon": item.children ? undefined : "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function () {
                          return [
                            !item.children
                              ? [
                                  !item.target
                                    ? [
                                        item.link === "/payment"
                                          ? _c(
                                              "v-list-item",
                                              {
                                                on: {
                                                  click: _vm.transferPayment,
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-list-item-title",
                                                  { staticClass: "px-3" },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(item.title) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "nuxt-link",
                                              {
                                                staticClass:
                                                  "menu-link router-link-exact-active router-link-active",
                                                attrs: {
                                                  to: item.link,
                                                  "aria-current": "page",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-list-item",
                                                  [
                                                    _c(
                                                      "v-list-item-title",
                                                      { staticClass: "px-3" },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(item.title) +
                                                            "\n                  "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                      ]
                                    : item.title === "FUKURI"
                                    ? [
                                        _c(
                                          "v-list-item",
                                          { on: { click: _vm.transferFukuri } },
                                          [
                                            _c(
                                              "v-list-item-title",
                                              { staticClass: "px-3" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#414141",
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(item.title))]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : [
                                        _c(
                                          "v-list-item",
                                          {
                                            attrs: {
                                              link: "",
                                              href: item.link,
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-list-item-title",
                                              { staticClass: "px-3" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#414141",
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(item.title))]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                ]
                              : [
                                  item.title === "クラウドファンディング" &&
                                  _vm.showCampfireNewBadge
                                    ? _c("v-badge", {
                                        attrs: {
                                          color: "error",
                                          content: "new",
                                          left: "",
                                          small: "",
                                          "offset-x": "22",
                                          "offset-y": "1",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        { staticClass: "px-3" },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.title) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _vm._v(" "),
                  _vm._l(item.children, function (list, idx) {
                    return _c(
                      "div",
                      { key: idx },
                      [
                        !list.target
                          ? [
                              _c(
                                "nuxt-link",
                                { attrs: { to: list.link } },
                                [
                                  _c(
                                    "v-list-item",
                                    { attrs: { link: "" } },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(list.title) +
                                            "\n                "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          : [
                              _c(
                                "a",
                                {
                                  attrs: { href: list.link, target: "_blank" },
                                },
                                [
                                  _c(
                                    "v-list-item",
                                    { attrs: { link: "" } },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(list.title) +
                                            "\n                "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                      ],
                      2
                    )
                  }),
                ],
                2
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }