// マスターデータストア
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

import {
  IAdvertisementType,
  IcategoryType,
  IsubsidyClass,
  IunsubscribereasonType,
  IAnalyseAction,
  IBABanks,
  IBABankBranches,
  IbankBranches,
  IUserTypes,
  ISeminarStatuses,
  IinterestingFeatrues,
  InewsCategories,
  ICompanyregisterStatuses,
  IActivityTypes,
  ICompanyTypes,
  IDocumentCategories,
  IChargeStatuses,
  ICompanyStatuses,
  ICsoCategories,
  ICsoStatuses,
  ICsoPublishStatuses,
  IChatRoomTypes,
  ICompanyInnovationHistoriesStatuses,
  ICmhStatuses,
  ICmhRequestTypes,
  IPiStatuses,
  IEventCodes,
  IRegisterReason,
  IUserCfpVariables,
  IPendoAccount,
  ILoginCompany,
  IPendoBankerAccount
} from '@/types/master';
import {
  getAdvertisementTypes,
  getCategoryTypes,
  getSubsidyClass,
  getUnsubscribeReasonType,
  getAnalyseAction,
  getBABanks,
  getBABankBranches,
  getBankBranches,
  getUserTypes,
  getSeminarStatuses,
  getInterestingFeatrues,
  getNewsCategories,
  getCompanyregisterStatuses,
  getActivityTypes,
  getCompanyTypes,
  getDocumentCategories,
  getChargeStatuses,
  getCompanyStatuses,
  getCsoCategories,
  getCsoStatuses,
  getCsoPublishStatuses,
  getChatRoomTypes,
  getCompanyInnovationHistoriesStatuses,
  getCmhStatuses,
  getCmhRequestTypes,
  getPiStatuses,
  getEventCodes,
  getRegisterReason,
  getUserCfpVariables,
  getPendoAccount,
  getLoginCompany, getPendoBankerAccount
} from '@/apis/master';

export interface State {
  advertisementType: IAdvertisementType[];
  categoryType: IcategoryType[];
  subsidyClass: IsubsidyClass;
  unsubscribereasonType: IunsubscribereasonType[];
  analyseAction: IAnalyseAction[];
  baBank: IBABanks[];
  baBankBranch: IBABankBranches[];
  bankBranch: IbankBranches[];
  userType: IUserTypes[];
  seminarStatus: ISeminarStatuses[];
  interestingFeatrue: IinterestingFeatrues[];
  newsCategory: InewsCategories[];
  companyregisterStatus: ICompanyregisterStatuses[];
  activityTypes: IActivityTypes[];
  companyType: ICompanyTypes[];
  documentCategory: IDocumentCategories[];
  chargeStatus: IChargeStatuses[];
  companyStatus: ICompanyStatuses[];
  csoCategories: ICsoCategories[];
  csoStatuses: ICsoStatuses[];
  csoPublishStatuses: ICsoPublishStatuses[];
  chatRoomTypes: IChatRoomTypes[];
  companyInnovationHistoriesStatuses: ICompanyInnovationHistoriesStatuses[];
  cmhStatus: ICmhStatuses[];
  cmhRequestTypes: ICmhRequestTypes[];
  piStatuses: IPiStatuses[];
  eventCodes: IEventCodes[];
  registerReason: IRegisterReason[];
  userCfpVariables: IUserCfpVariables[];
  pendoAccount: IPendoAccount;
  pendoBankerAccount: IPendoBankerAccount;
}

@Module({ stateFactory: true, namespaced: true, name: 'master' })
export default class Master extends VuexModule implements State {
  // マッチングニーズタイプ
  advertisementType: IAdvertisementType[] = [
    {
      ca_type_unique_code: '',
      ca_type_name: ''
    }
  ];

  // 業種タイプ
  categoryType: IcategoryType[] = [
    {
      company_cbt_1_code: '',
      company_cbt_1_name: '',
      cbt_2: [
        {
          company_cbt_1_code: '',
          company_cbt_2_code: '',
          company_cbt_2_name: '',
          company_cbt_2_group_code: '',
          company_cbt_2_group_name: ''
        }
      ]
    }
  ];

  // 補助金・助成金カテゴリー
  subsidyClass: IsubsidyClass = {
    class_1: [''],
    class_2: ['']
  };

  // 退会理由タイプ
  unsubscribereasonType: IunsubscribereasonType[] = [
    {
      id: '',
      text: ''
    }
  ];

  // アクション種別(マッチング)
  analyseAction: IAnalyseAction[] = [
    {
      analysis_action_type: '',
      analysis_action_name: ''
    }
  ];

  // BA銀行
  baBank: IBABanks[] = [];

  // BA銀行支店
  baBankBranch: IBABankBranches[] = [];

  // 銀行支店
  bankBranch: IbankBranches[] = [
    {
      bb_id: 0,
      bb_created_at: '',
      bb_updated_at: '',
      bb_deleted_at: '',
      bb_revision: 0,
      bb_unique_code: '',
      bb_code: '',
      bb_name: '',
      bb_order: 0
    }
  ];

  // ユーザー種別
  userType: IUserTypes[] = [
    {
      user_type: '',
      user_type_is_disabled: 0,
      user_type_name: '',
      user_role: 0,
      user_is_super_user: 0,
      user_type_order: 0,
      user_type_group: ''
    }
  ];

  // セミナーステータス
  seminarStatus: ISeminarStatuses[] = [
    {
      seminar_can_apply: 0,
      seminar_is_published: 0,
      seminar_status: '',
      seminar_status_name: ''
    }
  ];

  // 銀行支店
  interestingFeatrue: IinterestingFeatrues[] = [
    {
      interesting_feature_id: 0,
      interesting_feature_name: '',
      interesting_feature_sort_order: 0
    }
  ];

  // ニュースカテゴリ
  newsCategory: InewsCategories[] = [
    {
      content_info: '',
      important_info: '',
      info: '',
      system_info: ''
    }
  ];

  // 企業登録申請状態
  companyregisterStatus: ICompanyregisterStatuses[] = [
    {
      cr_is_closed: false,
      cr_is_rejected: false,
      cr_status: '',
      cr_status_name: ''
    }
  ];

  // アクティビティタイプ
  activityTypes: IActivityTypes[] = [
    {
      activity_type: '',
      activity_type_title: ''
    }
  ];

  // 企業登録申請状態
  companyType: ICompanyTypes[] = [];

  // ユーザ書類カテゴリ
  documentCategory: IDocumentCategories[] = [
    {
      document_category: '',
      document_category_name: '',
      document_category_order: 0
    }
  ];

  // 課金ステータス
  chargeStatus: IChargeStatuses[] = [
    {
      charge_status: '',
      charge_status_seq: '',
      charge_status_name: '',
      charge_status_color: '',
      charge_is_closed: ''
    }
  ];

  // 企業ステータス
  companyStatus: ICompanyStatuses[] = [];

  // カテゴリ名
  csoCategories: ICsoCategories[] = [
    {
      cso_category_name: '',
      cso_category_unique_code: ''
    }
  ];

  // 福利厚生提供サービスステータス
  csoStatuses: ICsoStatuses[] = [
    {
      cso_status: '',
      cso_status_name: ''
    }
  ];

  // 公開状況
  csoPublishStatuses: ICsoPublishStatuses[] = [
    {
      cso_publish_status: '',
      cso_publish_status_name: ''
    }
  ];

  // チャットルームタイプ
  chatRoomTypes: IChatRoomTypes[] = [
    {
      chatroom_type: '',
      chatroom_type_name: '',
      chatroom_type_is_hidden: 0,
      chatroom_required_approval_check: 0,
      chatroom_is_builtin: 0
    }
  ];

  // イノベーション履歴ステータス
  companyInnovationHistoriesStatuses: ICompanyInnovationHistoriesStatuses[] =
    [];

  // cmhステータス
  cmhStatus: ICmhStatuses[] = [
    {
      cmh_status: '',
      cmh_status_name: '',
      cmh_status_order: '',
      cmh_is_enable: '',
      cmh_is_closed: '',
      cmh_status_user_name: '',
      cmh_status_can_user_select: '',
      cmh_status_can_user_change: ''
    }
  ];

  // cmh request types
  cmhRequestTypes: ICmhRequestTypes[] = [
    {
      cmh_request_type: '',
      cmh_order: '',
      cmh_request_type_name_for_list: '',
      cmh_request_type_name_for_button: '',
      cmh_request_type_is_disabled: ''
    }
  ];

  // パートナー情報ステータス
  piStatuses: IPiStatuses[] = [
    {
      pi_status: '',
      pi_status_name: ''
    }
  ];

  // イベントコード
  eventCodes: IEventCodes[] = [
    {
      event_code: '',
      event_code_name: '',
      event_code_order: '',
      event_holding_days: ''
    }
  ];

  // きっかけ
  registerReason: IRegisterReason[] = [];

  // 商談関連チャット差込フィールド設定
  userCfpVariables: IUserCfpVariables[] = [];

  // Pendoのaccount情報
  pendoAccount: IPendoAccount = {
    id: '',
    type: '',
    industry_01: '',
    industry_02: '',
    industry_03: '',
    company_id: 0,
    company_created_at: '',
    company_is_charge_target: '',
    company_charge_amount: 0,
    company_charge_start_at: '',
    company_employee_count: 0,
    company_matching_count: 0,
    company_interesting_features: '',
    homepage: '',
    company_issues: '',
    company_forte: '',
    company_service: '',
    company_remarks: '',
    company_corporate_site: '',
    company_demo_accounts: '',
    company_employee_accounts: 0,
    company_business_accounts: 0,
    company_coupons: 0
  };

  // Pendoの金融機関側のaccount情報
  pendoBankerAccount: IPendoBankerAccount = {
    id: '',
    role: '',
    banker_status: '',
    banker_branch_office: '',
    bank_code: '',
    normal_company_count: 0,
    business_accounts_setting: '',
    ba_portal_setting: '',
    profit_matching_setting: '',
    ba_mall_setting: '',
    campfire_setting: ''
  };

  // Pendoのaccount情報
  loginCompany: ILoginCompany = {
    company_unique_code: '',
    company_name: '',
    company_type: '',
    cbt_name: '',
    company_cbt_1_name: '',
    company_cbt_2_name: '',
    company_small_business_type: '',
    company_employee_count: 0
  };

  @Mutation
  public SET_ADVERTISEMENT_TYPE(items: IAdvertisementType[]): void {
    this.advertisementType = items;
  }

  @Mutation
  public SET_CATEGORY_TYPE(items: IcategoryType[]): void {
    this.categoryType = items;
  }

  @Mutation
  public SET_SUBSIDY_CLASS(items: IsubsidyClass): void {
    this.subsidyClass = items;
  }

  @Mutation
  public SET_UNSUBSCRIBEREASON_TYPE(items: IunsubscribereasonType[]): void {
    this.unsubscribereasonType = items;
  }

  @Mutation
  public SET_ANALYSE_ACTION(items: IAnalyseAction[]): void {
    this.analyseAction = items;
  }

  @Mutation
  public SET_BA_BANK(items: IBABanks[]): void {
    this.baBank = items;
  }

  @Mutation
  public SET_BA_BANK_BRANCH(items: IBABankBranches[]): void {
    this.baBankBranch = items;
  }

  @Mutation
  public SET_BANK_BRANCH(items: IbankBranches[]): void {
    this.bankBranch = items;
  }

  @Mutation
  public SET_USER_TYPE(items: IUserTypes[]): void {
    this.userType = items;
  }

  @Mutation
  public SET_SEMINAR_STATUS(items: ISeminarStatuses[]): void {
    this.seminarStatus = items;
  }

  @Mutation
  public SET_INTERESTING_FEATURE(items: IinterestingFeatrues[]): void {
    this.interestingFeatrue = items;
  }

  @Mutation
  public SET_NEWS_CATEGORY(items: InewsCategories[]): void {
    const temp: any = [];
    Object.keys(items).forEach(function (key: any) {
      temp.push({ value: key, text: items[key] });
    });
    this.newsCategory = temp;
  }

  @Mutation
  public SET_COMPANYREGISTER_STATUS(items: ICompanyregisterStatuses[]): void {
    this.companyregisterStatus = items;
  }

  @Mutation
  public SET_ACTIVITY_TYPES(items: IActivityTypes[]): void {
    this.activityTypes = items;
  }

  @Mutation
  public SET_COMPANY_TYPE(items: ICompanyTypes[]): void {
    this.companyType = items;
  }

  @Mutation
  public SET_DOCUMENT_CATEGORY(items: IDocumentCategories[]): void {
    this.documentCategory = items;
  }

  @Mutation
  public SET_CHARGE_STATUS(items: IChargeStatuses[]): void {
    this.chargeStatus = items;
  }

  @Mutation
  public SET_COMPANY_STATUS(items: ICompanyStatuses): void {
    const keys = Object.keys(items);
    const values = Object.values(items);
    for (let i = 0; i < keys.length; i++) {
      this.companyStatus[i] = { value: keys[i], text: values[i] };
    }
  }

  @Mutation
  public SET_CSO_CATEGORY(items: ICsoCategories[]): void {
    this.csoCategories = items;
  }

  @Mutation
  public SET_CSO_STATUS(items: ICsoStatuses[]): void {
    this.csoStatuses = items;
  }

  @Mutation
  public SET_CSO_PUBLISH_STATUS(items: ICsoPublishStatuses[]): void {
    this.csoPublishStatuses = items;
  }

  @Mutation
  public SET_CHAT_ROOM_TYPES(items: IChatRoomTypes[]): void {
    const temp: any = [];

    Object.keys(items).forEach(function (key: any) {
      temp.push(items[key]);
    });

    this.chatRoomTypes = temp;
  }

  @Mutation
  public SET_COMPANY_INNOVATION_HISTORIES_STATUSES(
    items: ICompanyInnovationHistoriesStatuses[]
  ): void {
    this.companyInnovationHistoriesStatuses = items;
  }

  @Mutation
  public SET_CMH_STATUS(items: ICmhStatuses[]): void {
    this.cmhStatus = items;
  }

  @Mutation
  public SET_CMH_REQUEST_TYPES(items: ICmhRequestTypes[]): void {
    this.cmhRequestTypes = items;
  }

  @Mutation
  public SET_PI_STATUS(items: IPiStatuses[]): void {
    this.piStatuses = items;
  }

  @Mutation
  public SET_EVENT_CODES(items: IEventCodes[]): void {
    this.eventCodes = items;
  }

  @Mutation
  public SET_REGISTER_REASON(items: IRegisterReason[]): void {
    const temp: any = [];

    items.forEach(function (item: any) {
      temp.push({ value: item.label_id, text: item.label_name });
    });

    this.registerReason = temp;
  }

  @Mutation
  public SET_USER_CFP_VARIABLES(items: IUserCfpVariables[]): void {
    this.userCfpVariables = items;
  }

  @Mutation
  public SET_PENDO_ACCOUNT(item: IPendoAccount): void {
    this.pendoAccount = item;
  }

  @Mutation
  public SET_PENDO_BANKER_ACCOUNT(item: IPendoBankerAccount): void {
    this.pendoBankerAccount = item;
  }

  @Mutation
  public SET_LOGIN_COMPANY(items: ILoginCompany[]): void {
    if (items.length > 0) {
      this.loginCompany.company_unique_code = items[0].company_unique_code;
      this.loginCompany.company_name = items[0].company_name;
      this.loginCompany.company_type = items[0].company_type;
      this.loginCompany.cbt_name = items[0].cbt_name;
      this.loginCompany.company_cbt_1_name = items[0].company_cbt_1_name;
      this.loginCompany.company_cbt_2_name = items[0].company_cbt_2_name;
      this.loginCompany.company_small_business_type =
        items[0].company_small_business_type;
      this.loginCompany.company_employee_count =
        items[0].company_employee_count;
    }
  }

  @Action({ rawError: true })
  loadMaster(): Promise<boolean> {
    const promiseAdvertisement: any = getAdvertisementTypes();
    const promiseSetting: any = getCategoryTypes();
    const promisesubsidy: any = getSubsidyClass();
    const promiseUnsubscribereason: any = getUnsubscribeReasonType();
    const promiseAnalyseAction: any = getAnalyseAction();
    const promiseBABank = getBABanks();
    const promiseBABankBranch = getBABankBranches();
    const promiseBankBranch = getBankBranches();
    const promiseUserType = getUserTypes();
    const promiseSeminarStatus = getSeminarStatuses();
    const promiseInterestingFeatrue = getInterestingFeatrues();
    const promiseInewsCategory = getNewsCategories();
    const promiseCompanyregisterStatus = getCompanyregisterStatuses();
    const promiseActivityTypes = getActivityTypes();
    const promiseCompanyType = getCompanyTypes();
    const promiseDocumentCategory = getDocumentCategories();
    const promiseChargeStatuses = getChargeStatuses();
    const promiseCompanyStatuses = getCompanyStatuses();
    const promiseCsoCategory = getCsoCategories();
    const promiseCsoStatuses = getCsoStatuses();
    const promiseCsoPublishStatuses = getCsoPublishStatuses();
    const promiseChatRoomTypes = getChatRoomTypes();
    const promiseCompanyInnovationHistoriesStatuses =
      getCompanyInnovationHistoriesStatuses();
    const promiseCmhStatuses = getCmhStatuses();
    const promiseCmhRequestTypes = getCmhRequestTypes();
    const promisePiStatuses = getPiStatuses();
    const promiseEventCodes = getEventCodes();
    const promiseRegisterReason = getRegisterReason();
    const promiseUserCfpVariables = getUserCfpVariables();
    const promisePendoAccount = getPendoAccount();
    const promisePendoBankerAccount = getPendoBankerAccount();
    const promiseLoginCompany = getLoginCompany();

    return Promise.all([
      promiseAdvertisement,
      promiseSetting,
      promisesubsidy,
      promiseUnsubscribereason,
      promiseAnalyseAction,
      promiseBABank,
      promiseBABankBranch,
      promiseBankBranch,
      promiseUserType,
      promiseSeminarStatus,
      promiseInterestingFeatrue,
      promiseInewsCategory,
      promiseCompanyregisterStatus,
      promiseActivityTypes,
      promiseCompanyType,
      promiseDocumentCategory,
      promiseChargeStatuses,
      promiseCompanyStatuses,
      promiseCsoCategory,
      promiseCsoStatuses,
      promiseCsoPublishStatuses,
      promiseChatRoomTypes,
      promiseCompanyInnovationHistoriesStatuses,
      promiseCmhStatuses,
      promiseCmhRequestTypes,
      promisePiStatuses,
      promiseEventCodes,
      promiseRegisterReason,
      promiseUserCfpVariables,
      promisePendoAccount,
      promisePendoBankerAccount,
      promiseLoginCompany
    ]).then(
      ([
        advertisementType,
        categoryType,
        subsidyClass,
        unsubscribereasonType,
        analyseAction,
        baBank,
        baBankBranch,
        bankBranch,
        userType,
        seminarStatus,
        interestingFeature,
        newsCategory,
        companyregisterStatus,
        activityTypes,
        companyType,
        documentCategory,
        chargeStatus,
        companyStatus,
        csoCategory,
        csoStatus,
        csoPublishStatus,
        chatRoomTypes,
        companyInnovationHistoriesStatuses,
        cmhStatus,
        cmhRequestTypes,
        piStatuses,
        eventCodes,
        registerReason,
        userCfpVariables,
        pendoAccount,
        pendoBankerAccount,
        loginCompany
      ]) => {
        if (advertisementType && advertisementType.result) {
          this.SET_ADVERTISEMENT_TYPE(advertisementType.result.list);
        }
        if (categoryType && categoryType.result) {
          this.SET_CATEGORY_TYPE(categoryType.result.list);
        }
        if (subsidyClass && subsidyClass.result) {
          this.SET_SUBSIDY_CLASS(subsidyClass.result);
        }
        if (unsubscribereasonType && unsubscribereasonType.result) {
          this.SET_UNSUBSCRIBEREASON_TYPE(unsubscribereasonType.result.list);
        }
        if (analyseAction && analyseAction.result) {
          this.SET_ANALYSE_ACTION(analyseAction.result.list);
        }
        if (baBank && baBank.result) {
          this.SET_BA_BANK(baBank.result.list);
        }
        if (baBankBranch && baBankBranch.result) {
          this.SET_BA_BANK_BRANCH(baBankBranch.result.list);
        }
        if (bankBranch && bankBranch.result) {
          this.SET_BANK_BRANCH(bankBranch.result.list);
        }
        if (userType && userType.result) {
          this.SET_USER_TYPE(userType.result.list);
        }
        if (seminarStatus && seminarStatus.result) {
          this.SET_SEMINAR_STATUS(seminarStatus.result.list);
        }
        if (interestingFeature && interestingFeature.result) {
          this.SET_INTERESTING_FEATURE(interestingFeature.result.list);
        }
        if (newsCategory && newsCategory.result) {
          this.SET_NEWS_CATEGORY(newsCategory.result.list);
        }
        if (companyregisterStatus && companyregisterStatus.result) {
          this.SET_COMPANYREGISTER_STATUS(companyregisterStatus.result.list);
        }
        if (activityTypes && activityTypes.result) {
          this.SET_ACTIVITY_TYPES(activityTypes.result.list);
        }
        if (companyType && companyType.result) {
          this.SET_COMPANY_TYPE(companyType.result.list);
        }
        if (documentCategory && documentCategory.result) {
          this.SET_DOCUMENT_CATEGORY(documentCategory.result.list);
        }
        if (chargeStatus && chargeStatus.result) {
          this.SET_CHARGE_STATUS(chargeStatus.result.list);
        }
        if (companyStatus && companyStatus.result) {
          this.SET_COMPANY_STATUS(companyStatus.result.list);
        }
        if (csoCategory && csoCategory.result) {
          this.SET_CSO_CATEGORY(csoCategory.result.list);
        }
        if (csoStatus && csoStatus.result) {
          this.SET_CSO_STATUS(csoStatus.result.list);
        }
        if (csoPublishStatus && csoPublishStatus.result) {
          this.SET_CSO_PUBLISH_STATUS(csoPublishStatus.result.list);
        }
        if (chatRoomTypes && chatRoomTypes.result) {
          this.SET_CHAT_ROOM_TYPES(chatRoomTypes.result.list);
        }
        if (
          companyInnovationHistoriesStatuses &&
          companyInnovationHistoriesStatuses.result
        ) {
          this.SET_COMPANY_INNOVATION_HISTORIES_STATUSES(
            companyInnovationHistoriesStatuses.result.list
          );
        }
        if (cmhStatus && cmhStatus.result) {
          this.SET_CMH_STATUS(cmhStatus.result.list);
        }
        if (cmhRequestTypes && cmhRequestTypes.result) {
          this.SET_CMH_REQUEST_TYPES(cmhRequestTypes.result.list);
        }
        if (piStatuses && piStatuses.result) {
          this.SET_PI_STATUS(piStatuses.result.list);
        }
        if (eventCodes && eventCodes.result) {
          this.SET_EVENT_CODES(eventCodes.result.list);
        }
        if (registerReason && registerReason.result) {
          this.SET_REGISTER_REASON(registerReason.result.list);
        }
        // TODO:elseは後で消す
        if (userCfpVariables && userCfpVariables.result) {
          this.SET_USER_CFP_VARIABLES(userCfpVariables.result.list);
        } else {
          this.SET_USER_CFP_VARIABLES([]);
        }
        if (pendoAccount && pendoAccount.result) {
          this.SET_PENDO_ACCOUNT(pendoAccount.result);
        }
        if (pendoBankerAccount && pendoBankerAccount.result) {
          this.SET_PENDO_BANKER_ACCOUNT(pendoBankerAccount.result);
        }
        if (loginCompany && loginCompany.result) {
          this.SET_LOGIN_COMPANY(loginCompany.result.list);
        }
        return true;
      }
    );
  }

  @Action({ rawError: true })
  loadMasterForRegister(): Promise<boolean> {
    const promiseSetting: any = getCategoryTypes();
    const promiseBankBranch: any = getBankBranches();
    const promiseInterestingFeatrue: any = getInterestingFeatrues();
    const promiseRegisterReason = getRegisterReason();

    return Promise.all([
      promiseSetting,
      promiseBankBranch,
      promiseInterestingFeatrue,
      promiseRegisterReason
    ]).then(
      ([categoryType, bankBranch, interestingFeature, registerReason]) => {
        if (categoryType && categoryType.result) {
          this.SET_CATEGORY_TYPE(categoryType.result.list);
        }
        if (bankBranch && bankBranch.result) {
          this.SET_BANK_BRANCH(bankBranch.result.list);
        }
        if (interestingFeature && interestingFeature.result) {
          this.SET_INTERESTING_FEATURE(interestingFeature.result.list);
        }
        if (registerReason && registerReason.result) {
          this.SET_REGISTER_REASON(registerReason.result.list);
        }
        return true;
      }
    );
  }
}
