









































import {
  defineComponent,
  ref,
  useMeta,
  useRoute,
  watch,
  useContext,
  onMounted
} from '@nuxtjs/composition-api';
import Footer from '@/components/layout/footer.vue';
import { provideStore, useStore } from '@/compositions/store';
import { appStore, masterStore, userStore } from '@/store';
import service from '@/utils/api';
import { getBamAppConfig } from '@/apis/config';
import { getGoogleTagMangerHeaderSetting, getGoogleTagMangerBodySetting, getGoogleTagMangerBodySetting2, getPendoHeaderSetting, testBar } from '@/utils';
import Walkthrough from '@/components/walkthrough.vue';

// import '@/assets/css/reset.css';
// TODO:リセットを使うと管理画面に響くのでリセットを使わないでCSSの調整をするような処理をあとで入れる
import '@/assets/css/color.less';
import '@/assets/css/common.less';
import '@/assets/css/style.less';

export default defineComponent({
  components: { Footer, Walkthrough },
  setup() {
    // provideにキー付きでリアクティブな状態を登録(各コンポーネントで利用可能となる)
    provideStore();

    const { title, meta, link, script } = useMeta();

    const dialog = ref<boolean>(false);
    const initialized = ref<boolean>(false);

    const { breadcrumb, overlay, toastMessages, walkthrough, onResize } = useStore();

    const gaId = ref<string>('');
    const ctx = useContext();
    const route = useRoute();
    const ajustment = testBar() ? 'padding-top:12px' : 'padding-top:0';

    watch(
      () => route.value,
      () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore (GAのコードのため無視)
        if (appStore.appConfig && appStore.appConfig.ga_id && typeof gtag === 'function') {
          gaId.value = appStore.appConfig.ga_id;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore (GAのコードのため無視)
          gtag('config', gaId.value, { page_path: route.value.path });
        }
      }
    );

    const gtm_body = ref<string>('');
    const gtm_body2 = ref<string>('');
    // システム定義/カスタマイズ項目取得一時的な対応（サーバーサイドのマルチテナント化完了まで）
    getBamAppConfig()
      .then((r: any) => {
        if (r && r.result && r.result.api_url) {
          appStore.SET_BASE_URL(r.result.api_url);
        } else if (process.env.NAME === 'DEVELOPMENT' || process.env.NAME === 'STAGING' || process.env.NAME === 'PRODUCTION') {
          ctx.$sentry.captureMessage('サーバ情報が取得できませんでした');
          // ctx.error({
          //   statusCode: 500,
          //   message: '設定ファイルが読み込めませんでした'
          // });
          return;
        } else {
          appStore.SET_BASE_URL(process.env.API_URL || '');
        }
        service.defaults.baseURL = appStore.baseUrl;
      })
      .then(() => {
        appStore.loadConfig().then(() => {
          userStore.loadUser().then((r) => {
            if (!r) {
              return;
            }

            // せとうちDEMO(STBA)とココペリDEMO(XBA)にnoindex設定
            if (appStore.appConfig.bank_code === '9980' || appStore.appConfig.bank_code === '9998') {
              meta.value = [
                {
                  hid: 'robots',
                  name: 'robots',
                  content: 'noindex'
                }
              ];
            }

            gaId.value = appStore.appConfig.ga_id;
            title.value = `${appStore.appConfig.bank.ba_name} (${appStore.appConfig.bank.name})`;
            link.value.push(
              {
                rel: 'icon',
                type: 'image/x-icon',
                href: `/assets/img/customize/${appStore.systemPrefix}/favicon.ico`
              },
              {
                rel: 'apple-touch-icon',
                sizes: '180x180',
                href: `/assets/img/customize/${appStore.systemPrefix}/apple-touch-icon-180x180.png`
              });

            // gtm headerのスクリプト設定
            script.value = getGoogleTagMangerHeaderSetting(gaId.value, appStore.appConfig.gtm_id);

            // gtm bodyの設定
            gtm_body.value = getGoogleTagMangerBodySetting();

            // gtm bodyの設定
            gtm_body2.value = getGoogleTagMangerBodySetting2(appStore.appConfig.gtm_id);

            masterStore.loadMaster().then(() => {
              // Pendo headerのスクリプト設定
              const pendoHeader = getPendoHeaderSetting();
              script.value = [...script.value, ...pendoHeader];
              initialized.value = true;
            });
          }
          );
        });
      });

    const checkWalkthrough = ():void => {
      // ログイン直後かどうかの判定（ログイン直後のみwalkthroughを表示する）
      if (walkthrough.value &&
        (userStore.loginUser.user_type === 'company_owner' ||
          userStore.loginUser.user_type === 'partner_company_owner')
      ) {
        if (userStore.displayWalkthrough) {
          walkthrough.value = true;
        }
      } else {
        walkthrough.value = false;
      }
    };
    checkWalkthrough();

    onMounted(() => {
      onResize();
    });

    return { breadcrumb, dialog, initialized, overlay, toastMessages, appStore, gtm_body, gtm_body2, ajustment, testBar, walkthrough, onResize };
  },
  head: {}
});
