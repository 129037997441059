var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showFooter
    ? _c(
        "div",
        { staticStyle: { "margin-top": "80px" } },
        [
          _c("v-divider"),
          _vm._v(" "),
          _vm.$environment !== "PRODUCTION" && _vm.yappliRegisteredId
            ? _c("div", { staticClass: "pa-2 text-right" }, [
                _vm._v(
                  "\n    Yappli.sendRegisteredId = " +
                    _vm._s(_vm.yappliRegisteredId) +
                    "\n  "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.isMobile | !_vm.loggedIn
            ? _c(
                "div",
                [
                  _vm.appStore.context.state.appConfig.customize
                    .enable_footer_logo_img_visible
                    ? _c(
                        "v-row",
                        {
                          staticClass: "pt-5",
                          attrs: { justify: "center", "no-gutters": "" },
                        },
                        [
                          _c("v-img", {
                            attrs: {
                              contain: "",
                              "max-height": "40",
                              "max-width": "197.5",
                              src:
                                "/assets/img/customize/" +
                                _vm.appStore.context.state.systemPrefix +
                                "/bank_logo.png",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.appStore.context.state.appConfig.customize
                    .enable_footer_bank_code_visible
                    ? _c(
                        "v-row",
                        { attrs: { justify: "center", "no-gutters": "" } },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "pt-1",
                              staticStyle: { "font-size": "12px" },
                            },
                            [
                              _vm._v(
                                "\n        金融機関コード：" +
                                  _vm._s(
                                    _vm.appStore.context.state.appConfig.bank
                                      .code
                                  ) +
                                  "\n      "
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-footer",
                    {
                      staticClass: "mt-7",
                      attrs: { align: "center", dark: "", padless: "" },
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "pa-1",
                          attrs: { justify: "center", "no-gutters": "" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "white",
                                text: "",
                                to: "/page/rule",
                                nuxt: "",
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.appStore.settingContents
                                      .footer_rule_link_string
                                  ) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.appStore.context.state.appConfig.customize
                            .enable_footer_rc_rule_visible
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "white",
                                    text: "",
                                    to: "/page/rule_rc",
                                    nuxt: "",
                                  },
                                },
                                [_vm._v("\n          R&C会員規約\n        ")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "white",
                                text: "",
                                href: _vm.appStore.context.state.appConfig
                                  .privacy_url,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.appStore.settingContents
                                      .footer_privacy_policy_link_string
                                  ) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "white",
                                text: "",
                                to: "/inquiry",
                                nuxt: "",
                              },
                            },
                            [_vm._v("\n          お問い合わせ\n        ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isMobile
            ? [
                _vm.loggedIn && !_vm.appStore.appConfig.is_yappli_client
                  ? _c("ul", { staticClass: "sp-footer-menu" }, [
                      _c("li", [
                        _c("img", {
                          attrs: { src: require("@/assets/img/sp/menu.png") },
                          on: {
                            click: function ($event) {
                              _vm.mobileNavi = true
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v("\n        メニュー\n      "),
                      ]),
                      _vm._v(" "),
                      _vm.userStore.context &&
                      _vm.userStore.context.state.loginUser.user_type ===
                        "company_owner"
                        ? _c(
                            "li",
                            [
                              _c(
                                "nuxt-link",
                                { attrs: { to: "/mypage/matching/search" } },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/img/sp/matching.png"),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v("\n          マッチング\n        "),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c("nuxt-link", { attrs: { to: "/chat" } }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/img/sp/chat.png"),
                              },
                            }),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v("\n          チャット\n        "),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:;" },
                            on: { click: _vm.transferFukuri },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/img/sp/fukuri.png"),
                              },
                            }),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v("\n          福利厚生\n        "),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._m(0),
                    ])
                  : _vm.loggedIn && _vm.appStore.appConfig.is_yappli_client
                  ? _c(
                      "ul",
                      {
                        staticClass: "sp-footer-menu",
                        class: { "for-yappli": !_vm.mobileNavi },
                      },
                      [
                        _c("li", [
                          _c("img", {
                            attrs: { src: require("@/assets/img/sp/menu.png") },
                            on: {
                              click: function ($event) {
                                _vm.mobileNavi = true
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v("\n        メニュー\n      "),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("a", { attrs: { href: "/mypage/news" } }, [
        _c("img", { attrs: { src: require("@/assets/img/sp/news.png") } }),
        _vm._v(" "),
        _c("br"),
        _vm._v("\n          お知らせ\n        "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }