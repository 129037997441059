var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.appStore.appConfig.is_yappli_client
    ? _c(
        "div",
        {
          directives: [
            {
              name: "resize",
              rawName: "v-resize",
              value: _vm.onResize,
              expression: "onResize",
            },
          ],
        },
        [
          _c(
            "v-app-bar",
            {
              staticClass: "white",
              style: _vm.ajustment,
              attrs: { app: "", "clipped-left": "", dense: "" },
            },
            [
              !_vm.isMobile && _vm.switched
                ? _c(
                    "v-item-group",
                    [
                      _c("v-item", [
                        _c(
                          "a",
                          {
                            staticStyle: { cursor: "pointer" },
                            on: { click: _vm.cancelSwitching },
                          },
                          [
                            _vm.device === "pc"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pt-4 pb-1 text-center red darken-3",
                                    staticStyle: { width: "256px" },
                                  },
                                  [
                                    _c("v-icon", {
                                      attrs: { dark: "" },
                                      domProps: {
                                        textContent:
                                          _vm._s("mdi-account-switch"),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "caption white--text" },
                                      [
                                        _vm._v(
                                          "\n              ユーザー切替モード(戻る)\n            "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pt-4 pb-1 px-5 text-center red darken-3",
                                  },
                                  [
                                    _c("v-icon", {
                                      attrs: { dark: "" },
                                      domProps: {
                                        textContent:
                                          _vm._s("mdi-account-switch"),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "caption white--text" },
                                      [
                                        _vm._v(
                                          "\n              切替\n            "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.appStore.directAccess && _vm.$vuetify.breakpoint.width > 480
                ? _c("v-app-bar-nav-icon", {
                    staticClass: "ml-1",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.toggleMenu.apply(null, arguments)
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "ba_logo" },
                [
                  _vm._t("img", function () {
                    return [
                      _c("nuxt-link", { attrs: { to: "/" } }, [
                        _c("img", {
                          style: !_vm.isMobile
                            ? "object-fit: contain; margin-left: -130px;"
                            : "object-fit: contain;",
                          attrs: {
                            src:
                              "/assets/img/customize/" +
                              _vm.appStore.context.state.systemPrefix +
                              "/ba_logo.png",
                          },
                        }),
                      ]),
                    ]
                  }),
                  _vm._v(" "),
                  _vm.$vuetify.breakpoint.width > 940
                    ? _c(
                        "div",
                        [
                          _vm.loggedIn
                            ? _c(
                                "v-item-group",
                                { staticClass: "d-flex justify-end" },
                                [
                                  !_vm.hideLogoutButton
                                    ? _c("v-item", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pb-2 pt-1 pr-2 text-center",
                                            staticStyle: {
                                              cursor: "pointer",
                                              "margin-top": "-45px",
                                            },
                                          },
                                          [
                                            _c(
                                              "nuxt-link",
                                              {
                                                attrs: {
                                                  to: "/logout",
                                                  "data-logout": "",
                                                },
                                              },
                                              [
                                                _c("v-icon", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      "far fa-sign-out grey--text"
                                                    ),
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "caption black--text",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  ログアウト\n                "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("v-item", [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: "/mypage/help",
                                          "data-qa": "",
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pb-2 pt-1 pl-2 pr-6 text-center",
                                            staticStyle: {
                                              cursor: "pointer",
                                              "margin-top": "-45px",
                                            },
                                          },
                                          [
                                            _c("v-icon", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  "fa-question-circle grey--text"
                                                ),
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "caption black--text",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  ヘルプ\n                "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$vuetify.breakpoint.width <= 940
                    ? _c(
                        "span",
                        [
                          _vm.$vuetify.breakpoint.width > 480
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "my-2",
                                  attrs: {
                                    to: "/logout",
                                    small: "",
                                    text: "",
                                    icon: "",
                                  },
                                },
                                [
                                  _c("v-icon", {
                                    attrs: { dark: "" },
                                    domProps: {
                                      textContent: _vm._s(
                                        "far fa-sign-out grey--text"
                                      ),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "my-2 mr-4",
                              attrs: {
                                href: "/mypage/help",
                                small: "",
                                text: "",
                                icon: "",
                                "data-qa": "",
                                target: "_blank",
                              },
                            },
                            [
                              _c("v-icon", {
                                domProps: {
                                  textContent: _vm._s(
                                    "fa-question-circle grey--text"
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.testBar() ? _c("div", { staticClass: "test-border" }) : _vm._e(),
        ],
        1
      )
    : _c(
        "div",
        [
          _vm.$vuetify.breakpoint.width > 480
            ? _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: {
                    fixed: "",
                    small: "",
                    right: "",
                    top: "",
                    color: "primary",
                    elevation: "2",
                    fab: "",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.toggleMenu.apply(null, arguments)
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-menu")])],
                1
              )
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }