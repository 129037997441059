














































































import {
  defineComponent,
  ref,
  useMeta,
  watch,
  useRoute,
  useRouter,
  useContext,
  onMounted,
  watchEffect
} from '@nuxtjs/composition-api';
import GlobalNavigation from '@/components/layout/navigation.vue';
import Footer from '@/components/layout/footer.vue';
import { provideStore, useStore } from '@/compositions/store';
import { appStore, masterStore, userStore } from '@/store';
import service from '@/utils/api';
import { getBamAppConfig } from '@/apis/config';
import { getGoogleTagMangerHeaderSetting, getGoogleTagMangerBodySetting, getGoogleTagMangerBodySetting2, getPendoHeaderSetting, isNeedYappli, isLocalStorageavAilable, testBar } from '@/utils';
import Walkthrough from '@/components/walkthrough.vue';

// import '@/assets/css/reset.css';
// TODO:リセットを使うと管理画面に響くのでリセットを使わないでCSSの調整をするような処理をあとで入れる
import '@/assets/css/color.less';
import '@/assets/css/common.less';
import '@/assets/css/style.less';

export default defineComponent({
  components: { GlobalNavigation, Footer, Walkthrough },
  setup() {
    // provideにキー付きでリアクティブな状態を登録(各コンポーネントで利用可能となる)
    provideStore();

    const { title, meta, link, script } = useMeta();

    const {
      isAdmin,
      onResize,
      isMobile,
      device,
      breadcrumb,
      overlay,
      toastMessages,
      countNotifications,
      forSelectItems,
      showFooter,
      setFooter,
      yappliRegisteredId,
      setYappliInfo,
      walkthrough
    } = useStore();

    const dialog = ref<boolean>(false);
    const initialized = ref<boolean>(false);
    const mounted = ref<boolean>(false);
    const registeredId = ref<boolean>(false);

    // ページフッターのデフォルトをフッター有りにしておく
    const route = useRoute();
    const router = useRouter();
    const ctx = useContext();
    const gaId = ref<string>('');
    const ajustment = testBar() ? 'padding-top:12px' : 'padding-top:0';

    watch(
      () => route.value,
      () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore (GAのコードのため無視)
        if (appStore.appConfig && appStore.appConfig.ga_id && typeof gtag === 'function') {
          gaId.value = appStore.appConfig.ga_id;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore (GAのコードのため無視)
          gtag('config', gaId.value, { page_path: route.value.path });
        }

        setFooter(true);
      }
    );

    watchEffect(() => {
      if (!registeredId.value && yappliRegisteredId.value && mounted.value) {
        Yappli.sendRegisteredId(userStore.loginUser.user_unique_code);
        console.log('sended:' + userStore.loginUser.user_unique_code);
        registeredId.value = true;
      }
    });

    const gtm_body = ref<string>('');
    const gtm_body2 = ref<string>('');
    // システム定義/カスタマイズ項目取得一時的な対応（サーバーサイドのマルチテナント化完了まで）
    getBamAppConfig()
      .then((r: any) => {
        if (r && r.result && r.result.api_url) {
          appStore.SET_BASE_URL(r.result.api_url);
        } else if (process.env.NAME === 'DEVELOPMENT' || process.env.NAME === 'STAGING' || process.env.NAME === 'PRODUCTION') {
          ctx.$sentry.captureMessage('サーバ情報が取得特できませんでした。isLocalStorageavAilable:' + isLocalStorageavAilable());
          return;
        } else {
          appStore.SET_BASE_URL(process.env.API_URL || '');
        }
        service.defaults.baseURL = appStore.baseUrl;
      })
      .then(() => {
        // システム定義/カスタマイズ項目取得
        Promise.all([
          appStore.loadConfig(),
          userStore.loadUser(),
          countNotifications(),
          forSelectItems()
        ]).then((r) => {
          if (!r[0]) {
            // ctx.$sentry.captureMessage('設定ファイルが読み込めませんでした');
            // ctx.error({
            //   statusCode: 401,
            //   message: '設定ファイルが読み込めませんでした'
            // });
            return;
          }

          const needRedirect = ():string|null => {
            // クレカ登録がない場合、クレカ登録画面を強制表示する
            if (appStore.appConfig.check_payment_method_filled && !appStore.appConfig.is_payment_method_filled) {
              return '/mypage/payment/card/create';
            }
            return null;
          };

          const redirectTo = needRedirect();
          // routerを利用していない画面遷移対応
          if (redirectTo && !location.pathname.startsWith('/mypage/payment')) {
            location.href = redirectTo;
            return;
          }
          // routerを利用していない画面遷移対応
          if (location.pathname.startsWith('/mypage/forum')) {
            if (!appStore.appConfig.enable_forum || (userStore.loginUser.user_type !== 'company_owner' && userStore.loginUser.user_type !== 'partner_company_owner')) {
              location.href = '/mypage/dashboard';
              return;
            }

            if (appStore.appConfig.enable_forum && location.pathname !== '/mypage/forum/policy') {
              appStore.loadForumConfig().then((r:any) => {
                if (!r) {
                  location.href = '/mypage/forum/policy';
                }
              });
            }
          }
          // routerを利用していない画面遷移対応（MALL）
          if (location.pathname.startsWith('/mypage/mall')) {
            if (!appStore.appConfig.enable_mall || (userStore.loginUser.user_type !== 'company_owner' && userStore.loginUser.user_type !== 'partner_company_owner' && userStore.loginUser.user_type !== 'company_user')) {
              location.href = '/mypage/dashboard';
              return;
            }

            if (appStore.appConfig.enable_mall && location.pathname !== '/mypage/mall/policy') {
              appStore.loadMallConfig().then((r:any) => {
                if (!r) {
                  location.href = '/mypage/mall/policy';
                }
              });
            }
          }
          // routerを利用していない画面遷移対応(NICC)
          if (location.pathname.startsWith('/mypage/chanto-attendance')) {
            // BAMの設定がfalseの場合は、/mypage/dashboardに遷移
            if (!appStore.appConfig.enable_icnext) {
              location.href = '/mypage/dashboard';
              return;
            }
            // オーナー権限以外の場合は、403エラー
            if (appStore.appConfig.enable_icnext && userStore.loginUser.user_type !== 'company_owner') {
              ctx.error({
                statusCode: 403,
                message: '権限がありません'
              });
            }
          }

          router.beforeResolve((to, _, next) => {
            const redirectTo = needRedirect();
            const excludes = ['/login', '/logout', '/mypage/payment/card/create', '/mypage/forum/policy', '/mypage/mall/policy'];

            if (excludes.includes(to.path)) {
              next();
              return;
            }

            // 掲示板同意なし
            if (appStore.appConfig.enable_forum && !appStore.activatedForum && to.path.startsWith('/mypage/forum')) {
              appStore.loadForumConfig().then((r) => {
                if (!r) {
                  next('/mypage/forum/policy');
                }
              });
            }

            // MALL同意なし
            if (appStore.appConfig.enable_mall && !appStore.activatedMall && to.path.startsWith('/mypage/mall')) {
              appStore.loadMallConfig().then((r) => {
                if (!r) {
                  next('/mypage/mall/policy');
                }
              });
            }

            if (redirectTo && to.path !== redirectTo) {
              next(redirectTo);
              return;
            }
            next();
          });

          // せとうちDEMO(STBA)とココペリDEMO(XBA)にnoindex設定
          if (appStore.appConfig.bank_code === '9980' || appStore.appConfig.bank_code === '9998') {
            meta.value = [
              {
                hid: 'robots',
                name: 'robots',
                content: 'noindex'
              }
            ];
          }

          gaId.value = appStore.appConfig.ga_id;
          title.value = `${appStore.appConfig.bank.ba_name} (${appStore.appConfig.bank.name})`;
          link.value.push(
            {
              rel: 'icon',
              type: 'image/x-icon',
              href: `/assets/img/customize/${appStore.systemPrefix}/favicon.ico`
            },
            {
              rel: 'apple-touch-icon',
              sizes: '180x180',
              href: `/assets/img/customize/${appStore.systemPrefix}/apple-touch-icon-180x180.png`
            }
          );

          // gtm headerのスクリプト設定
          script.value = getGoogleTagMangerHeaderSetting(gaId.value, appStore.appConfig.gtm_id);

          // gtm bodyの設定
          gtm_body.value = getGoogleTagMangerBodySetting();

          // gtm bodyの設定
          gtm_body2.value = getGoogleTagMangerBodySetting2(appStore.appConfig.gtm_id);

          // yappliのsetRegisteredId情報設定
          if (isNeedYappli()) {
            setYappliInfo(userStore.loginUser.user_unique_code);
          }

          masterStore.loadMaster().then(() => {
            // Pendo headerのスクリプト設定
            const pendoHeader = getPendoHeaderSetting();
            script.value = [...script.value, ...pendoHeader];
            initialized.value = true;
          });
        });
      });

    const checkWalkthrough = ():void => {
      // ログイン直後かどうかの判定（ログイン直後のみwalkthroughを表示する）
      if (walkthrough.value &&
        (userStore.loginUser.user_type === 'company_owner' ||
          userStore.loginUser.user_type === 'partner_company_owner')
      ) {
        if (userStore.displayWalkthrough) {
          walkthrough.value = true;
        }
      } else {
        walkthrough.value = false;
      }
    };
    checkWalkthrough();

    onMounted(() => {
      mounted.value = true;
      onResize();
    });

    return {
      onResize,
      breadcrumb,
      dialog,
      initialized,
      isAdmin,
      isMobile,
      device,
      overlay,
      toastMessages,
      showFooter,
      appStore,
      gtm_body,
      gtm_body2,
      yappliRegisteredId,
      mounted,
      registeredId,
      ajustment,
      walkthrough
    };
  },
  head: {}
});
