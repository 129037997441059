import { IAccessPermission } from '@/types/middleware/routeAccessPermissions';
import { OPERATION_PERMISSIONS } from '@/configs/enums/permissions';

/** アクセス権限の定義 */
export const routeAccessPermissions: IAccessPermission[] = [
  {
    /** マッチング検索 */
    routePath: '/mypage/matching/search',
    accessRequirements: {
      permissions: [
        OPERATION_PERMISSIONS.COMPANY_SETTING_MATCHING_WRITE,
        OPERATION_PERMISSIONS.COMPANY_SETTING_COMPANY_WRITE,
        OPERATION_PERMISSIONS.COMPANY_MATCHING_READ
      ],
      allowedUserTypes: []
    },
    errorInfo: {
      statusCode: 403,
      errorMessage: '権限がありません'
    }
  },
  {
    /** 商談管理 */
    routePath: '/mypage/matching/received_history',
    accessRequirements: {
      permissions: [
        OPERATION_PERMISSIONS.COMPANY_MATCHING_READ
      ],
      allowedUserTypes: []
    },
    errorInfo: {
      statusCode: 403,
      errorMessage: '権限がありません'
    }
  }
];
