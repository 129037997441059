import type { Plugin } from '@nuxt/types';
import {
  now,
  formatDate,
  formatDateTime,
  formatTime,
  truncate,
  formatNumber,
  requirePermission,
  anyPermission,
  requireChatPermission,
  implode,
  linkify,
  nl2br,
  imgUrl
} from '@/utils/filter';
import { decodeJwt } from '@/utils';
import { MAX_ROWS, PAGINATION_OPTIONS } from '@/configs/admin';
import { DATA_ENABLE } from '@/configs';
import { IIdToken } from '@/types';

type decodeJwt = () => IIdToken | null;
type formatDate = (value: string | null, format: string) => string;
type formatDateTime = (value: string | null, format: string) => string;
type formatTime = (value: string | null, format: string) => string;
type truncate = (
  value: string | null,
  specified_length?: any,
  specified_omission?: string
) => string;
type formatNumber = (value: string | null) => string;
type implode = (value: string | null) => string;
type MAX_ROWS = { value: number; text: string }[];
type PAGINATION_OPTIONS = {
  enabled: boolean;
  mode: string;
  position: string;
  nextLabel: string;
  prevLabel: string;
  rowsPerPageLabel: string;
  perPageDropdown: [number];
  dropdownAllowAll: boolean;
  perPage: number;
  setCurrentPage: number;
};
type DATA_ENABLE = { value: number; text: string }[];

type fileBaseUrl = string;
type environment = string;
type imgUrl = string;

// javascript内で使うときは定義する
declare module '@nuxt/types' {
  interface Context {
    $decodeJwt: decodeJwt;
    $formatDate: formatDate;
    $formatDateTime: formatDateTime;
    $formatTime: formatTime;
    $truncate: truncate;
    $formatNumber: formatNumber;
    $implode: implode;
    $MAX_ROWS: MAX_ROWS;
    $PAGINATION_OPTIONS: PAGINATION_OPTIONS;
    $DATA_ENABLE: DATA_ENABLE;
    $fileBaseUrl: fileBaseUrl;
    $environment: environment;
    $imgUrl: imgUrl;
  }
}

const fillterPlugin: Plugin = (_context, inject) => {
  inject('decodeJwt', decodeJwt);
  inject('now', now);
  inject('formatDateTime', formatDateTime);
  inject('formatDate', formatDate);
  inject('formatTime', formatTime);
  inject('truncate', truncate);
  inject('formatNumber', formatNumber);
  inject('requirePermission', requirePermission);
  inject('requireChatPermission', requireChatPermission);
  inject('anyPermission', anyPermission);
  inject('implode', implode);
  inject('linkify', linkify);
  inject('nl2br', nl2br);
  inject('MAX_ROWS', MAX_ROWS);
  inject('PAGINATION_OPTIONS', PAGINATION_OPTIONS);
  inject('DATA_ENABLE', DATA_ENABLE);
  inject('fileBaseUrl', process.env.BAM_BASE_URL);
  inject('environment', process.env.NAME);
  inject('imgUrl', imgUrl);
};

export default fillterPlugin;
