


















































import { defineComponent, ref, useMeta, useRoute, watch, useContext } from '@nuxtjs/composition-api';
import ApplicationBar from '@/components/layout/applicationbar.vue';
import Footer from '@/components/layout/footer.vue';
import { provideStore, useStore } from '@/compositions/store';
import { appStore, masterStore } from '@/store';
import service from '@/utils/api';
import { getBamAppConfig } from '@/apis/config';
import { getGoogleTagMangerHeaderSetting, getGoogleTagMangerBodySetting, getGoogleTagMangerBodySetting2, testBar } from '@/utils';

// import '@/assets/css/reset.css';
// TODO:リセットを使うと管理画面に響くのでリセットを使わないでCSSの調整をするような処理をあとで入れる
import '@/assets/css/color.less';
import '@/assets/css/common.less';
import '@/assets/css/style.less';

export default defineComponent({
  components: { ApplicationBar, Footer },
  setup() {
    // provideにキー付きでリアクティブな状態を登録(各コンポーネントで利用可能となる)
    provideStore();

    const { title, meta, link, script } = useMeta();

    const dialog = ref<boolean>(false);
    const initialized = ref<boolean>(false);

    const {
      breadcrumb,
      overlay,
      toastMessages
    } = useStore();

    const gaId = ref<string>('');
    const ctx = useContext();
    const route = useRoute();

    const ajustment = testBar() ? 'padding-top:12px' : 'padding-top:0';

    watch(
      () => route.value,
      () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore (GAのコードのため無視)
        if (appStore.appConfig && appStore.appConfig.ga_id && typeof gtag === 'function') {
          gaId.value = appStore.appConfig.ga_id;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore (GAのコードのため無視)
          gtag('config', gaId.value, { page_path: route.value.path });
        }
      }
    );

    const gtm_body = ref<string>('');
    const gtm_body2 = ref<string>('');
    // システム定義/カスタマイズ項目取得一時的な対応（サーバーサイドのマルチテナント化完了まで）
    getBamAppConfig()
      .then((r: any) => {
        if (r && r.result && r.result.api_url) {
          appStore.SET_BASE_URL(r.result.api_url);
        } else if (process.env.NAME === 'DEVELOPMENT' || process.env.NAME === 'STAGING' || process.env.NAME === 'PRODUCTION') {
          ctx.$sentry.captureMessage('サーバ情報が取得特できませんでした');
          return;
        } else {
          appStore.SET_BASE_URL(process.env.API_URL || '');
        }
        service.defaults.baseURL = appStore.baseUrl;
      })
      .then(() => {
        // システム定義/カスタマイズ項目取得
        appStore.loadConfig().then(() => {
          Promise.all([
            masterStore.loadMasterForRegister()
          ]).then((r) => {
            if (!r[0]) {
              // ctx.$sentry.captureMessage('設定ファイルが読み込めませんでした');
              // ctx.error({
              //   statusCode: 401,
              //   message: '設定ファイルが読み込めませんでした'
              // });
              return;
            }

            meta.value = [
              {
                hid: 'robots',
                name: 'robots',
                content: 'noindex'
              }
            ];

            gaId.value = appStore.appConfig.ga_id;
            title.value = `${appStore.appConfig.bank.ba_name} (${appStore.appConfig.bank.name})`;
            link.value.push(
              {
                rel: 'icon',
                type: 'image/x-icon',
                href: `/assets/img/customize/${appStore.systemPrefix}/favicon.ico`
              },
              {
                rel: 'apple-touch-icon',
                sizes: '180x180',
                href: `/assets/img/customize/${appStore.systemPrefix}/apple-touch-icon-180x180.png`
              }
            );

            // gtm headerのスクリプト設定
            script.value = getGoogleTagMangerHeaderSetting(gaId.value, appStore.appConfig.gtm_id);

            // gtm bodyの設定
            gtm_body.value = getGoogleTagMangerBodySetting();

            // gtm bodyの設定
            gtm_body2.value = getGoogleTagMangerBodySetting2(appStore.appConfig.gtm_id);

            initialized.value = true;
          });
        });
      });

    // ポータルだったらヘッダー画像の差し替えとフッターを消す
    const isPortal = ref<boolean>(false);
    if (location.pathname.indexOf('/register/portal') === 0) {
      isPortal.value = true;
    }

    return {
      appStore,
      breadcrumb,
      dialog,
      initialized,
      overlay,
      toastMessages,
      isPortal,
      gtm_body,
      gtm_body2,
      ajustment,
      testBar
    };
  },
  head: {}
});
