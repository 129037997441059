var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.initialized
    ? _c(
        "v-app",
        {
          directives: [
            {
              name: "resize",
              rawName: "v-resize",
              value: _vm.onResize,
              expression: "onResize",
            },
          ],
          style: _vm.ajustment,
          attrs: { dark: "" },
        },
        [
          _c("noscript", [
            _c("span", { domProps: { innerHTML: _vm._s(_vm.gtm_body) } }),
          ]),
          _vm._v(" "),
          _c("noscript", [
            _c("span", { domProps: { innerHTML: _vm._s(_vm.gtm_body2) } }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("GlobalNavigation"),
              _vm._v(" "),
              _c(
                "v-main",
                {
                  staticClass: "top",
                  class: { bachat: _vm.showFooter === false },
                },
                [
                  _vm.breadcrumb &&
                  _vm.breadcrumb.pathname &&
                  _vm.breadcrumb.pathname != "/mypage/dashboard"
                    ? _c("div", [
                        _c("div", { staticClass: "page-title" }, [
                          _c("h1", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.breadcrumb.title) +
                                " /\n            "
                            ),
                            _c("small", [
                              _vm._v(_vm._s(_vm.breadcrumb.subTitle)),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm.breadcrumb.links
                          ? _c(
                              "div",
                              { staticClass: "pb-7" },
                              [
                                _c("v-breadcrumbs", {
                                  attrs: { items: _vm.breadcrumb.links },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "item",
                                        fn: function (ref) {
                                          var item = ref.item
                                          return [
                                            _c(
                                              "v-breadcrumbs-item",
                                              {
                                                attrs: {
                                                  to: item.href,
                                                  exact: true,
                                                  nuxt: "",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      item.text.replace(
                                                        "[bank_name]",
                                                        _vm.appStore.appConfig
                                                          .bank.name
                                                      )
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "divider",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  "x-small": "",
                                                  color: "#ccc",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                mdi-play\n              "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    1087870692
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("nuxt"),
                  _vm._v(" "),
                  _vm.$environment !== "PRODUCTION" && _vm.registeredId
                    ? _c("div", { staticClass: "pa-2 text-right" }, [
                        _vm._v(
                          "\n        Yappli.sendRegisteredId 実行\n      "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("Footer", {
                    attrs: { dialog: _vm.dialog },
                    on: {
                      close: function ($event) {
                        _vm.dialog = false
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.toastMessages, function (messages, type) {
                    return _c(
                      "div",
                      { key: type },
                      _vm._l(messages, function (show, message) {
                        return _c(
                          "div",
                          { key: message },
                          [
                            _c(
                              "v-snackbar",
                              {
                                attrs: {
                                  color: type,
                                  timeout: "5000",
                                  "content-class": "text-center",
                                },
                                model: {
                                  value: _vm.toastMessages[type][message],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.toastMessages[type],
                                      message,
                                      $$v
                                    )
                                  },
                                  expression: "toastMessages[type][message]",
                                },
                              },
                              [_c("b", [_vm._v(_vm._s(message))])]
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    )
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "v-overlay",
                { attrs: { value: _vm.overlay } },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", size: "64" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.walkthrough && _vm.mounted ? _c("Walkthrough") : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }