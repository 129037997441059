
































































































import { defineComponent, useContext, ref } from '@nuxtjs/composition-api';
import { appStore, userStore } from '@/store';
import { useStore } from '@/compositions/store';
import { openFukuri } from '@/utils';

export default defineComponent({
  name: 'Footer',
  setup() {
    const { showFooter, isMobile, mobileNavi, yappliRegisteredId } = useStore();

    const loggedIn = ref<boolean>(false);

    const ctx = useContext();
    loggedIn.value = ctx.$auth.loggedIn;

    const transferFukuri = (): void => {
      openFukuri();
    };

    return { loggedIn, appStore, userStore, showFooter, isMobile, mobileNavi, transferFukuri, yappliRegisteredId };
  }
});
