var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        scrollable: "",
        "max-width": _vm.getMaxWidth(true),
        height: _vm.getMaxHeight(),
      },
      model: {
        value: _vm.showWalkthrough,
        callback: function ($$v) {
          _vm.showWalkthrough = $$v
        },
        expression: "showWalkthrough",
      },
    },
    [
      _c(
        "v-carousel",
        {
          attrs: {
            "hide-delimiters": _vm.device === "sp" ? true : false,
            cycle: "",
            continuous: false,
            interval: "20000",
            height: _vm.getMaxHeight(),
          },
          on: {
            change: function ($event) {
              return _vm.writeLog($event)
            },
          },
        },
        _vm._l(_vm.items, function (item, i) {
          return _c(
            "v-carousel-item",
            { key: i },
            [
              _c(
                "v-sheet",
                {
                  staticStyle: {
                    overflow: "hidden scroll",
                    "padding-bottom": "50px",
                  },
                  attrs: { height: _vm.getMaxHeight(), tile: "" },
                },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _vm.userStore.displayWalkthrough
                                ? _c("v-checkbox", {
                                    staticClass: "pl-2 mt-1",
                                    attrs: {
                                      label: "次回から表示しない",
                                      dense: "",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: _vm.isNeverDisplay,
                                      callback: function ($$v) {
                                        _vm.isNeverDisplay = $$v
                                      },
                                      expression: "isNeverDisplay",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: { click: _vm.close },
                                },
                                [
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v(
                                      "\n                  mdi-close\n                "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "pt-3",
                              attrs: { justify: "center" },
                            },
                            [
                              _c("v-img", {
                                staticClass: "my-6",
                                attrs: {
                                  src:
                                    _vm.device === "pc" ? item.img : item.spImg,
                                  "max-width": _vm.getMaxWidth(false),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }