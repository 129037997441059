


























































import { defineComponent, reactive, toRefs } from '@nuxtjs/composition-api';
import { useStore } from '@/compositions/store';
import { HTMLInputEvent } from '@/types';
import { Save } from '@/compositions/base-save';
import { userStore } from '@/store';

export default defineComponent({
  name: 'Walkthrough',
  layout: 'plain',
  setup() {
    const state = reactive<any>({
      e1: 1,
      items: [
        {
          id: 1,
          title: 'Name of step 1',
          img: require('@/assets/img/walkthrough/walk_through01.png'),
          spImg: require('@/assets/img/walkthrough/walk_through01_sp.png')
        },
        {
          id: 2,
          title: 'Name of step 2',
          img: require('@/assets/img/walkthrough/walk_through02.png'),
          spImg: require('@/assets/img/walkthrough/walk_through02_sp.png')
        },
        {
          id: 3,
          title: 'Name of step 3',
          img: require('@/assets/img/walkthrough/walk_through03.png'),
          spImg: require('@/assets/img/walkthrough/walk_through03_sp.png')
        },
        {
          id: 4,
          title: 'Name of step 4',
          img: require('@/assets/img/walkthrough/walk_through04.png'),
          spImg: require('@/assets/img/walkthrough/walk_through04_sp.png')
        },
        {
          id: 5,
          title: 'Name of step 5',
          img: require('@/assets/img/walkthrough/walk_through05.png'),
          spImg: require('@/assets/img/walkthrough/walk_through05_sp.png')
        }
      ],
      showWalkthrough: true,
      isNeverDisplay: false
    });

    const { windowWidth, windowHeight, walkthrough, device } = useStore();
    const { setSaveConfig, saveData } = Save();

    // 画面のサイズ合う高さを取得
    const getMaxHeight = ():string => {
      const num:number = (windowWidth.value < 600) ? 1.2 : 1;
      if (device.value === 'pc') {
        return windowHeight.value * 0.78 + 'px';
      } else if (device.value === 'tablet') {
        return windowHeight.value * 0.83 * num + 'px';
      } else {
        return windowHeight.value * 0.75 + 'px';
      }
    };

    // 画面のサイズ合う幅を取得
    const getMaxWidth = (img:boolean):string => {
      const num:number = (img) ? 1 : 0.87;
      if (device.value === 'pc') {
        return windowWidth.value * 0.5 * num + 'px';
      } else if (device.value === 'tablet') {
        return windowWidth.value * 0.9 * num + 'px';
      } else {
        return windowWidth.value * 0.92 * num + 'px';
      }
    };

    const writeLog = (currentPage:HTMLInputEvent):void => {
      // 0から渡ってくるので、ページ数に直すための+1
      const pageNum = Number(currentPage) + 1;
      setSaveConfig('/api/v1/walkthrough/log');
      saveData({ data: { key: 'walkthrough_pv', value: 'page' + pageNum } });
    };

    const next = (idx:number): number => {
      if (idx === state.items.length) {
        return 1;
      }
      return idx + 1;
    };

    const close = (): void => {
      if (userStore.displayWalkthrough && state.isNeverDisplay) {
        // 0:非表示でWT表示設定を更新する。一度オフにすると以後チェックボックスは表示されなくなる
        setSaveConfig('/api/v1/walkthrough/setting');
        saveData({ data: { wt_display: 0 } }).then(() => {
          userStore.loadUser();
        });
      }
      state.showWalkthrough = false;
      walkthrough.value = false;
    };

    return {
      ...toRefs(state),
      device,
      next,
      getMaxHeight,
      getMaxWidth,
      writeLog,
      walkthrough,
      close,
      userStore
    };
  }
});
