
















































































































import {
  defineComponent,
  onMounted,
  useContext,
  ref,
  useRouter
} from '@nuxtjs/composition-api';
import { useStore } from '@/compositions/store';
import { appStore, userStore } from '@/store';
import { testBar } from '@/utils';

export default defineComponent({
  name: 'ApplicationBar',
  props: {
    hideLogoutButton: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  setup(_, { emit }) {
    const { isAdmin, onResize, isMobile, walkthrough, device } = useStore();
    const ajustment = testBar() ? 'height: 54px; top:12px' : 'height: 54px; top:0';

    const loggedIn = ref<boolean>(false);
    const switched = ref<boolean>(false);

    const ctx = useContext();
    loggedIn.value = ctx.$auth.loggedIn;

    if (localStorage.getItem('user_switch_from')) {
      switched.value = true;
    }

    onMounted(() => {
      onResize();
    });

    // サイドメニュー表示・非表示
    const toggleMenu = ():void => {
      emit('toggle');
    };

    // 切替解除
    const router = useRouter();
    const cancelSwitching = () :void => {
      router.push('/login');
    };

    return {
      appStore,
      userStore,
      isAdmin,
      isMobile,
      onResize,
      loggedIn,
      switched,
      cancelSwitching,
      ajustment,
      testBar,
      walkthrough,
      toggleMenu,
      device
    };
  }
});
