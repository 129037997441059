
























































































































































































































































































































































































import { defineComponent, useRoute, watch, ref, useContext } from '@nuxtjs/composition-api';
import { GLOBAL_MENU } from '@/configs';
import { useStore } from '@/compositions/store';
import { userStore, appStore } from '@/store';
import { requirePermission } from '@/utils/filter';
import ApplicationBar from '@/components/layout/applicationbar.vue';
import icnextApplicationModal from '~/components/icnext/application.vue';
import { StringKeyObject } from '@/types';
import { openFukuri, openPayment, testBar, isShowCampfireNewBadge, isShowBusinessSuccessionNewBadge, isShowIcnextLinkageNewBadge, isShowPaymentNewBadge } from '@/utils';

export default defineComponent({
  name: 'GlobalNavigation',
  components: { ApplicationBar, icnextApplicationModal },
  setup() {
    const {
      isAdmin,
      mobileNavi,
      unreadCmiCount,
      bachatRoomsUnreadCount,
      chatRoomsUnreadCount,
      setOpenSC,
      openSC,
      device,
      icnextDisplayModal,
      setIcnextModal
    } = useStore();

    const items = ref<any>([]);
    const route = useRoute();
    let ajustment = testBar() ? 'margin-top: 66px' : 'margin-top: 54px';
    const drawer = ref<boolean>(true);

    let skipFukuri = false;

    const setNavi = ():void => {
      // PCより小さい場合はデフォルトでサイドメニューを表示しない
      drawer.value = device.value === 'pc';
      if (appStore.appConfig.is_yappli_client) {
        ajustment = 'margin-top: 0';
      }
      GLOBAL_MENU.forEach(function(value: any, _) {
        // スイッチユーザー
        if (userStore.disableChat && value.title === 'ビジネスチャット') {
          return;
        }
        // ユーザータイプチェック
        if (value.user_type) {
          if (value.user_type_exclude) {
            if (value.user_type.includes(userStore.loginUser.user_type)) {
              return;
            }
          } else if (!value.user_type.includes(userStore.loginUser.user_type)) {
            return;
          }
        }
        // 企業タイプチェック
        if (value.company_type) {
          if (value.company_type.startsWith('!')) {
            if (userStore.loginUser.company_type === value.company_type.slice(1)) {
              return;
            }
          } else if (userStore.loginUser.company_type !== value.company_type) {
            return;
          }
        }
        // 経営掲示板
        if (!appStore.appConfig.enable_forum && value.title === '経営相談掲示板') {
          return;
        }

        // BA Mall
        if (!appStore.appConfig.enable_mall && value.title === 'お得な外部サービス') {
          return;
        }

        // ちゃんと請求書(payment)
        if (!appStore.appConfig.enable_payment && value.title === 'ちゃんと請求書') {
          return;
        }

        if (!value.required && !value.sub) {
          items.value.push(value);
          return;
        }

        // FUKURI
        if (skipFukuri && value.title === '福利厚生(FUKURI)') {
          return;
        }

        // クラウドファンディング
        if (!appStore.appConfig.enable_campfire && value.title === 'クラウドファンディング') {
          return;
        }

        // 勤怠連携機能
        if (!appStore.appConfig.enable_icnext && value.title === '勤怠連携サービス') {
          return;
        }

        if (value.title === '勤怠連携サービス') {
          const sub: { title: string; path: string }[] = [];
          value.sub.forEach(function (subValue: any) {
            if (subValue.user_type.includes(userStore.loginUser.user_type)) {
              sub.push(subValue);
            }
          });
          value.sub = sub;
        }

        // 事業承継(オーナーだった場合のみ)
        if (!appStore.appConfig.enable_business_succession && value.title === '事業承継') {
          return;
        }

        if ((value.required && requirePermission(value.required)) || !value.required) {
          if (!value.sub) {
            items.value.push(value);
          } else {
            const sub: { title: string; path: string }[] = [];
            value.sub.forEach(function (subValue: any) {
              // ビジネスユーザー設定
              if (subValue.path === '/mypage/setting/account/businessuser' && hasPermission(subValue.required)) {
                if (appStore.appConfig.enable_business_user) {
                  sub.push(subValue);
                }
              } else if (hasPermission(subValue.required) && hasAppConfig(subValue.appConfigRequired)) {
                sub.push(subValue);
              }
            });
            value.sub = sub;
            items.value.push(value);
          }
          // FUKURI
          if (value.title === '福利厚生(FUKURI)') {
            if (value.required[0] === 'company_setting_coupon_write' || userStore.loginUser.user_is_super_user) {
              skipFukuri = true;
            }
          }
        }
      });
    };

    const transferFukuri = (): void => {
      openFukuri();
    };

    const transferPayment = (): void => {
      openPayment();
    };

    const hasPermission = (required: any): boolean => {
      if (!required) {
        return true;
      }

      return requirePermission(required);
    };

    const hasAppConfig = (appConfigRequired: any): boolean => {
      if (!appConfigRequired) {
        return true;
      }

      // 判定のためにappConfigに型を付与する
      const appConfig: StringKeyObject = appStore.appConfig;
      return appConfig[appConfigRequired] === 1;
    };

    const showIcnextDialog = ref<boolean>(false);
    const showIcnextApplicationModal = (): any => {
      // ローカルストレージにfalseで保存されていた場合はstoreの値をfalseで上書きする。
      if (localStorage.getItem('icnext_display_modal') === 'false') {
        setIcnextModal(false);
      }

      // icnextDisplayModalがfalseの場合は、ダイアログを表示せず別タブ遷移。
      if (icnextDisplayModal.value) {
        showIcnextDialog.value = !showIcnextDialog.value;
      } else {
        setIcnextModal(false);
        window.open(appStore.icnextApplicationUrl, '_blank');
      }
    };

    setOpenSC();
    setNavi();

    // メニューが選択されたらメニューを閉じる
    watch(
      () => route.value, () => {
        drawer.value = device.value === 'pc';
        mobileNavi.value = false;
      }
    );

    // サイドメニュー表示・非表示
    const toggleMenu = ():void => {
      if (device.value !== 'sp') {
        mobileNavi.value = false;
        drawer.value = !drawer.value;
      } else {
        mobileNavi.value = true;
      }
    };

    // いつクラウドファンディングを確認したかのフラグを追加(存在していたらnewバッジを出すか出さないかのを確認)
    const ctx = useContext();
    const showCampfireNewBadge = ref<boolean>(isShowCampfireNewBadge(ctx.$formatDateTime((new Date()).toString(), 'YYYY-MM-DD HH:mm:ss')));

    // いつ事業承継を確認したかのフラグを追加(存在していたらnewバッジを出すか出さないかのを確認)
    const showBusinessSuccessionNewBadge = ref<boolean>(isShowBusinessSuccessionNewBadge(ctx.$formatDateTime((new Date()).toString(), 'YYYY-MM-DD HH:mm:ss')));

    // いつ勤怠連携機能を確認したかのフラグを追加(存在していたらnewバッジを出すか出さないかのを確認)
    const showAttendanceLinkageNewBadge = ref<boolean>(isShowIcnextLinkageNewBadge(ctx.$formatDateTime((new Date()).toString(), 'YYYY-MM-DD HH:mm:ss')));

    const showPaymentNewBadge = ref<boolean>(isShowPaymentNewBadge(ctx.$formatDateTime((new Date()).toString(), 'YYYY-MM-DD HH:mm:ss')));

    return {
      userStore,
      items,
      isAdmin,
      mobileNavi,
      unreadCmiCount,
      bachatRoomsUnreadCount,
      chatRoomsUnreadCount,
      openSC,
      appStore,
      transferFukuri,
      transferPayment,
      showCampfireNewBadge,
      drawer,
      showIcnextDialog,
      icnextDisplayModal,
      showIcnextApplicationModal,
      toggleMenu,
      device,
      showBusinessSuccessionNewBadge,
      ajustment,
      showAttendanceLinkageNewBadge,
      showPaymentNewBadge
    };
  }
});
