var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.initialized
    ? _c(
        "v-app",
        { staticClass: "admin", attrs: { id: "inspire" } },
        [
          _c("noscript", [
            _c("span", { domProps: { innerHTML: _vm._s(_vm.gtm_body) } }),
          ]),
          _vm._v(" "),
          _c("noscript", [
            _c("span", { domProps: { innerHTML: _vm._s(_vm.gtm_body2) } }),
          ]),
          _vm._v(" "),
          _vm.testBar() ? _c("div", { staticClass: "test-border" }) : _vm._e(),
          _vm._v(" "),
          _c(
            "v-app-bar",
            {
              staticClass: "grey darken-4 px-5 pb-10",
              style: _vm.ajustment,
              attrs: { app: "", dark: "", "clipped-left": "" },
            },
            [
              _c("v-app-bar-nav-icon", {
                on: {
                  click: function ($event) {
                    _vm.showMenu = !_vm.showMenu
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "v-toolbar",
                { staticClass: "mt-n2 px-9", attrs: { dense: "" } },
                [
                  _c(
                    "v-toolbar-title",
                    { staticClass: "pt-2 text-center white--text" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.appStore.context.state.appConfig.bank.name
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.isMobile
                    ? [
                        _c("v-spacer"),
                        _vm._v(" "),
                        _vm.unapprovedChatCount > 0
                          ? _c(
                              "v-badge",
                              {
                                staticClass: "pl-5 mt-5",
                                attrs: {
                                  color: "pink",
                                  content: _vm.unapprovedChatCount,
                                  "data-tooltip": "未承認チャット",
                                },
                              },
                              [
                                _c(
                                  "nuxt-link",
                                  {
                                    attrs: {
                                      to: "/admin/company/support/approval",
                                    },
                                  },
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-comment-check-outline"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.noreplyChatCount > 0
                          ? _c(
                              "v-badge",
                              {
                                staticClass: "pl-5 mt-5",
                                attrs: {
                                  color: "pink",
                                  content: _vm.noreplyChatCount,
                                  "data-tooltip": "未返信",
                                },
                              },
                              [
                                _c(
                                  "nuxt-link",
                                  { attrs: { to: "/admin/bachat/noreply" } },
                                  [_c("v-icon", [_vm._v("mdi-message-text")])],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        (_vm.closedInquiryCount > 0 &&
                          _vm.userStore.context.state.loginUser.user_type ===
                            "system_admin") ||
                        _vm.userStore.context.state.loginUser.user_type ===
                          "bank_admin"
                          ? _c(
                              "v-badge",
                              {
                                staticClass: "pl-5 mt-2",
                                attrs: {
                                  color:
                                    _vm.closedInquiryCount > 0 ? "pink" : "",
                                  content: _vm.closedInquiryCount,
                                  "data-tooltip": "お問い合わせ",
                                },
                              },
                              [
                                _c(
                                  "nuxt-link",
                                  { attrs: { to: "/admin/inquiry" } },
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-comment-question-outline"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-main",
            [
              _c("AdimnGlobalNavigation"),
              _vm._v(" "),
              _c(
                "v-container",
                { class: _vm.mainAjustment, attrs: { fluid: "" } },
                [_c("nuxt")],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("Footer"),
          _vm._v(" "),
          _vm._l(_vm.toastMessages, function (messages, type) {
            return _c(
              "div",
              { key: type },
              _vm._l(messages, function (show, message) {
                return _c(
                  "div",
                  { key: message },
                  [
                    _c(
                      "v-snackbar",
                      {
                        attrs: {
                          color: type,
                          timeout: "5000",
                          "content-class": "text-center",
                        },
                        model: {
                          value: _vm.toastMessages[type][message],
                          callback: function ($$v) {
                            _vm.$set(_vm.toastMessages[type], message, $$v)
                          },
                          expression: "toastMessages[type][message]",
                        },
                      },
                      [_c("b", [_vm._v(_vm._s(message))])]
                    ),
                  ],
                  1
                )
              }),
              0
            )
          }),
          _vm._v(" "),
          _c(
            "v-overlay",
            { attrs: { value: _vm.overlay } },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", size: "64" },
              }),
            ],
            1
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }