// システム定義ストア
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';

import { IAppConfig } from '@/types/app';
import { getAppConfig, getSettingContents, getAdminMenu, getIcnextServiceRegistration, getIcnextUserRegistrationUrl, getIcnextApplicationUrl } from '@/apis/config';
import { getForumUserInfo, getMallUserInfo } from '@/apis/user';
export interface State {
  baseUrl: string;
  appConfig: IAppConfig;
  settingContents: any;
  navMenuItems: any;
  systemPrefix: string;
  activatedForum: number;
  activatedMall: number;
  directAccess: boolean;
  icnextServiceRegistrationUrl: string;
  icnextRuleText: string;
  icnextUserRegistrationUrl: string;
  icnextApplicationUrl: string;
}

declare module '@nuxt/types' {
  interface Context {
    $appConfig: State;
  }
}
/**
 * システム設定・カスタマイズ設定をストアへ登録
 *
 * @export
 * @class App
 * @extends {VuexModule}
 * @implements {State}
 */
@Module({ stateFactory: true, namespaced: true, name: 'app' })
export default class App extends VuexModule implements State {
  baseUrl = '';
  appConfig: IAppConfig = {
    enable_mall: false,
    enable_forum: false,
    enable_campfire: false,
    enable_icnext: false,
    enable_payment: false,
    campfire_new_badge_display_period: '',
    enable_business_succession: false,
    business_succession_new_badge_display_period: '',
    icnext_new_badge_display_period: '',
    payment_new_badge_display_period: '',
    enable_auba_admin: false,
    enable_auba_company: false,
    chat_api_base_uri: '',
    shares_api_base_uri: '',
    api_key: '',
    env: '',
    name: '',
    build_version: '',
    system_prefix: '',
    url: '',
    idp_base_url: '',
    bamanager_url: '',
    bank_code: '',
    bank: {
      code: '',
      name: '',
      ba_name: '',
      hide_bank_code: false,
      contact_email: '',
      contact_name: '',
      contact_tel: ''
    },
    babank: {
      babank_unique_code: '',
      babank_code: '',
      babank_name: ''
    },
    baportal_url: null,
    employee_invitation_url: '',
    succession_apply_categories: [],
    baportal_is_enable: false,
    baportal_name: '',
    privacy_url: '',
    shares_base_url: '',
    copyright: '',
    enable_ca_recoomendation: true,
    card_brand_list: '',
    is_logged_in: false,
    is_yappli_client: false,
    is_android_client: false,
    customize: {
      enable_global_top_header_register_btn: true,
      enable_global_top_main_register_btn: true,
      enable_global_top_function_list_register_btn: true,
      enable_global_top_footer_register_btn: true,
      enable_global_top_price_table: true,
      enable_global_top_price_table_for_sp: true,
      enable_global_top_partner_logos: true,
      enable_register_index_identification: true,
      enable_register_index_other_manager_check: true,
      enable_cso_offer_url: false,
      enable_footer_bank_code_visible: true,
      enable_footer_logo_img_visible: true,
      enable_footer_rc_rule_visible: false,
      enable_account_info: false,
      enable_bank_branches: true,
      enable_chat_link: false,
      enable_company_rep_birthday_required: false,
      enable_main_account: false,
      enable_ncba_portal: false,
      enable_mypage_seminar_detail_enrty_form: true,
      enable_subscribe_reason: false,
      enable_google_analytics_tag: false,
      enable_register_reason: false
    },
    enable_business_user: false,
    enable_matching_project: false,
    can_register_creditcard: false,
    enable_baportal: false,
    ca_alive_confirm_duration: '',
    ga_id: '',
    gtm_id: '',
    post_login_redirect_uri_cookie_name: '',
    post_login_redirect_uri_cookie_domain: '',
    check_payment_method_filled: false,
    is_payment_method_filled: false
  };

  settingContents: any = {};
  navMenuItems: string[] = [];
  systemPrefix = '';
  activatedForum = 0;
  activatedMall = 0;
  directAccess = false;
  icnextServiceRegistrationUrl = '';
  icnextRuleText = '';
  icnextUserRegistrationUrl = '';
  icnextApplicationUrl = '';

  @Mutation
  public SET_BASE_URL(url: string): void {
    this.baseUrl = url;
  }

  @Mutation
  public SET_APP_CONFIG_VALUE(item: IAppConfig): void {
    this.appConfig = item;
    this.systemPrefix = item.system_prefix.toLowerCase();
  }

  @Mutation
  public SET_ACTIVATE_FORUM_SERVICE(value: number): void {
    this.activatedForum = value;
  }

  @Mutation
  public SET_ACTIVATE_MALL_SERVICE(value: number): void {
    this.activatedMall = value;
  }

  @Mutation
  public SET_SETTING_CONTENTS_VALUE(item: IAppConfig): void {
    this.settingContents = item;
  }

  @Mutation
  public SET_NAVIGATION_ITEMS(items: []): void {
    const tmp: any = [];
    items.forEach((elem: any) => {
      const children: any = [];
      if (elem.selenium_class === 'slt_pQbzlLR2Y1hhld7QL ') {
        elem.title = 'アカウント';
      }

      // 不要な情報を抜く
      if (elem.children) {
        elem.children.forEach((child: any) => {
          // 1.0用の/login/logoutから2.0用に置換
          if (child.link === '/login/logout') {
            child.link = '/logout';
          }

          // typeは抜く
          if (!child.type) {
            children.push({
              title: child.title,
              link: child.link,
              target: child.target || null
            });
          }
        });

        tmp.push({
          title: elem.title,
          link: elem.link,
          target: elem.target || null,
          children
        });
      } else {
        tmp.push({
          title: elem.title,
          link: elem.link,
          target: elem.target || null
        });
      }
    });

    this.navMenuItems = tmp;
  }

  @Mutation
  public SET_DIRECT_ACCESS(item: boolean): void {
    this.directAccess = item;
  }

  @Mutation
  public SET_ICNEXT_SERVICE_REGISTRATION(item: { icnext_url: string; icnext_rule_text: string }): void {
    this.icnextServiceRegistrationUrl = item.icnext_url;
    this.icnextRuleText = item.icnext_rule_text;
  }

  @Mutation
  public SET_ICNEXT_USER_REGISTRATION_URL(url: string): void {
    this.icnextUserRegistrationUrl = url;
  }

  @Mutation
  public SET_ICNEXT_APPLICATION_URL(url: string): void {
    this.icnextApplicationUrl = url;
  }

  /**
   * システム設定API・カスタマイズ設定APIを並列で実行しストア登録を行う
   *
   * @return {boolean}  {Promise<boolean>}
   * @memberof App
   */
  @Action({ rawError: true })
  loadConfig(): Promise<boolean> {
    const promiseAppConfig: any = getAppConfig();
    const promiseSettingContent: any = getSettingContents();

    return Promise.all([promiseAppConfig, promiseSettingContent]).then(
      ([app, setting]) => {
        if (!app || !setting) {
          return false;
        }
        if (!app.result || !setting.result) {
          return false;
        }
        this.SET_APP_CONFIG_VALUE(app.result.config);

        const global_top_top_logo = setting.result.list.global_top_top_logo;
        setting.result.list.global_top_top_logo = global_top_top_logo.replace(
          /xbaasset\/img/g,
          'assets/img/customize/' + this.systemPrefix
        );
        const global_top_cc = setting.result.list.global_top_cc;
        setting.result.list.global_top_cc = global_top_cc.replace(
          /xbaasset\/img/g,
          'assets/img/customize/' + this.systemPrefix
        );

        this.SET_SETTING_CONTENTS_VALUE(setting.result.list);

        // 勤怠連携機能利用あり
        if (this.appConfig.enable_icnext) {
          this.loadIcnextConfig().then((r: any) => {
            return r;
          });
        }

        return true;
      }
    );
  }

  /**
   * システム設定API・カスタマイズ設定APIを並列で実行しストア登録を行う
   *
   * @return {boolean}  {Promise<boolean>}
   * @memberof App
   */
  @Action({ rawError: true })
  loadMenu(): Promise<boolean> {
    const promiseUserNav: any = getAdminMenu();

    return Promise.all([promiseUserNav]).then(([nav]) => {
      // 管理画面用のメニューを取得
      if (nav && nav.result) {
        this.SET_NAVIGATION_ITEMS(nav.result.list);
      }
      return true;
    });
  }

  /**
   * 掲示板利用規約同意確認設定
   *
   * @return {boolean}  {Promise<boolean>}
   * @memberof App
   */
  @Action({ rawError: true })
  async loadForumConfig(): Promise<boolean> {
    // 掲示板利用あり
    if (!this.appConfig.enable_forum) {
      return false;
    }

    const data = await getForumUserInfo();

    if (data && data.result) {
      if (data.result.item.agree_term) {
        // 同意あり
        this.SET_ACTIVATE_FORUM_SERVICE(1);
        return true;
      } else {
        // 同意なし
        this.SET_ACTIVATE_FORUM_SERVICE(0);
        return false;
      }
    }

    self.$nuxt.context.error({
      statusCode: 500,
      message:
        'システム内でエラーが発生しました。お手数ですが時間をおいて再度アクセスしてください。'
    });
    return false;
  }

  /**
   * MALL利用規約同意確認設定
   *
   * @return {boolean}  {Promise<boolean>}
   * @memberof App
   */
  @Action({ rawError: true })
  async loadMallConfig(): Promise<boolean> {
    // MALL利用あり
    if (!this.appConfig.enable_mall) {
      return false;
    }

    const data = await getMallUserInfo();

    if (data && data.result) {
      if (data.result.item.agree_terms) {
        // 同意あり
        this.SET_ACTIVATE_MALL_SERVICE(1);
        return true;
      } else {
        // 同意なし
        this.SET_ACTIVATE_MALL_SERVICE(0);
        return false;
      }
    }

    self.$nuxt.context.error({
      statusCode: 500,
      message:
        'システム内でエラーが発生しました。お手数ですが時間をおいて再度アクセスしてください。'
    });
    return false;
  }

  /**
   * ユーザ情報APIを並列で実行しストア登録を行う
   *
   * @return {boolean}  {Promise<boolean>}
   * @memberof User
   */
  @Action({})
  setDirectAccess(flg: boolean): void {
    this.SET_DIRECT_ACCESS(flg);
  }

  /**
   * Icnext関連URL取得
   *
   * @return {boolean}  {Promise<boolean>}
   * @memberof App
   */
  @Action({ rawError: true })
  loadIcnextConfig(): Promise<boolean> {
    const promiseIcnextServiceRegistration: any = getIcnextServiceRegistration();
    const promiseIcnextUserRegistrationUrl: any = getIcnextUserRegistrationUrl();
    const promiseIcnextApplicationUrl: any = getIcnextApplicationUrl();

    return Promise.all([promiseIcnextServiceRegistration, promiseIcnextUserRegistrationUrl, promiseIcnextApplicationUrl]).then((r) => {
      if (r[0] === null || r[1] === null || r[2] === null) {
        return false;
      }

      if (r[0].status === 'error' || r[1].status === 'error' || r[2].status === 'error') {
        return false;
      }

      if (r[0].result.item.icnext_url && r[1].result.item.icnext_url && r[2].result.item.icnext_url) {
        this.SET_ICNEXT_SERVICE_REGISTRATION(r[0].result.item);
        this.SET_ICNEXT_USER_REGISTRATION_URL(r[1].result.item.icnext_url);
        this.SET_ICNEXT_APPLICATION_URL(r[2].result.item.icnext_url);
        return true;
      }
      return false;
    });
  }
}
